import { React, useEffect, useState } from "react";
import ContactItem from "../../../molecules/Providers/ContactItem";
import AddressForm from "../../../molecules/Common/AddressForm";
import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import LGModal from "../../../molecules/LGModal";
import {
  addProviderAddress,
  getProviderAddressesList,
  getProviderContactList,
} from "../../../api/providers";
import AddressCard from "../../../molecules/Common/AddressCard";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import AddContactForm from "../../../molecules/Providers/AddContactForm/index.tsx";

export default function ProviderContactList({ props }) {
  const { id } = props;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [contactList, setContactList] = useState([]);
  const [addressesList, setAddressesList] = useState([]);

  const { openSnack } = useSnack();

  const handleModalClose = async () => {
    setOpenModal(false);
  };

  const handleAddContact = async () => {
    setLoading(true);
    setOpenModal(false);
    await fetchContacts();
    setLoading(false);
  };

  const handleAddContactClick = () => {
    setModalData({
      header: "Agregar Contacto",
      body: <AddContactForm providerId={id} onSubmit={handleAddContact} />,
    });
    setOpenModal(true);
  };

  const handleAddAddressClick = () => {
    setModalData({
      header: "Agregar Dirección",
      body: <AddressForm props={{ id: 0, onSubmit: handleSubmitAddress }} />,
    });
    setOpenModal(true);
  };

  const handleSubmitAddress = async (addressPayload) => {
    const payload = { ...addressPayload, providerId: id };
    try {
      setLoading(true);
      const res = await addProviderAddress(payload);
      await fetchAddresses();
      setOpenModal(false);
      setLoading(false);
    } catch (e) {
      openSnack(handleError(e));
    }
  };

  const fetchContacts = async () => {
    try {
      const res = await getProviderContactList(id);
      setContactList(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchAddresses = async () => {
    try {
      const res = await getProviderAddressesList(id);
      setAddressesList(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    if (id) {
      fetchContacts();
      fetchAddresses();
    }
  }, [id]);

  return (
    <>
      <LGModal
        open={openModal}
        header={modalData.header}
        handleClose={handleModalClose}
      >
        {modalData.body}
      </LGModal>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid
          container
          mt={4}
          spacing={2}
          justifyContent={"left"}
          alignItems={"stretch"}
        >
          <Grid item xs={9}>
            <Typography variant="h4">Direcciones</Typography>
          </Grid>
          <Grid justifySelf={"left"} item xs={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<Icon>add</Icon>}
              onClick={handleAddAddressClick}
            >
              Agregar
            </Button>
          </Grid>
          <Grid container spacing={2} item xs={12} alignContent={"stretch"}>
            {addressesList &&
              addressesList.map((e, index) => (
                <Grid item xs={4} key={index}>
                  <AddressCard address={e.address} />
                </Grid>
              ))}
          </Grid>

          <Grid item xs={9}>
            <Typography variant="h4">Contacto</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<Icon>add</Icon>}
              onClick={handleAddContactClick}
            >
              Agregar
            </Button>
          </Grid>
        </Grid>
      )}
      {contactList &&
        contactList.map((e, index) => <ContactItem key={index} props={e} />)}
    </>
  );
}
