import React, { useEffect, useState } from "react";
import LGCrud from "../LGCrud";
import {
  addExpenseAccount,
  deleteExpenseAccount,
  getEconomicActivityList,
  getExpenseAccountList,
  updateExpenseAccount,
} from "../../api/accounting";
import LGSelectCellRenderer from "../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../LGCrud/LGSelectCellEditor";
import { percentageFormatter } from "../../services/valueFormatters";

export default function CrudExpenseAccounts() {
  const [expenseAccountsList, setExpenseAccountsList] = useState([]);
  const [economicActivityList, setEconomicActivityList] = useState([]);

  const fetchExpenseAccounts = async () => {
    const res = await getExpenseAccountList();
    setExpenseAccountsList(res.data);
  };

  const fetchEconomicActivities = async () => {
    const res = await getEconomicActivityList();
    setEconomicActivityList(res.data);
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.economicActivityId = payload.economicActivity.id;
    const res = await addExpenseAccount(payload);
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    payload.economicActivityId = payload.economicActivity.id;
    const res = await updateExpenseAccount(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteExpenseAccount(id);
  };

  useEffect(() => {
    fetchExpenseAccounts();
    fetchEconomicActivities();
  }, []);
  return (
    <LGCrud
      data={expenseAccountsList}
      columns={[
        {
          field: "economicActivity",
          headerName: "Actividad Económica",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) =>
                `${v.productServiceType.code}-${v.contpaqiPrefixCode} | ${v.name}`
              }
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={economicActivityList}
              labelTemplate={(v) =>
                `${v.productServiceType.code}-${v.contpaqiPrefixCode} | ${v.name}`
              }
            />
          ),
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
        {
          field: "contpaqiSuffixCode",
          headerName: "Código",
          flex: 0.5,
          editable: true,
        },
        {
          field: "completeContpaqiCode",
          headerName: "Cuenta Completa",
          flex: 0.5,
          editable: false,
        },
        {
          field: "creditableIva",
          headerName: "IVA acreditable",
          flex: 0.5,
          editable: true,
          ...percentageFormatter,
        },
        {
          field: "WithheldIva",
          headerName: "IVA retenido",
          flex: 0.5,
          editable: true,
          ...percentageFormatter,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
        fetchData: getExpenseAccountList,
      }}
    />
  );
}
