import { Box } from "@mui/material";
import React from "react";
import PurchaseOrderForm from "./PurchaseOrderForm";

export default function POUpdateForm({ onSubmit, id }) {
  return (
    <Box>
      <PurchaseOrderForm onSubmit={onSubmit} id={id} />
    </Box>
  );
}
