import React from 'react';
import { Grid } from '@mui/material';
import CPOShipmentCard from './CPOShipmentCard';

const CPOShipmentList = ({ shipments }) => {
  return (
    <Grid container spacing={2}>
      {shipments.map((shipment) => (
        <Grid item xs={12} key={shipment.id}>
          <CPOShipmentCard data={shipment} />
        </Grid>
      ))}
    </Grid>
  );
};

export default CPOShipmentList; 