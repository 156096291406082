import { api } from "..";
const endpoint = "/api/finished-goods";

export const getFinishedGoodList = async (queryParams) => {
  return await api.get(`${endpoint}`, { params: queryParams });
};

export const createFinishedGood = async (data) => {
  return await api.post(`${endpoint}`, data);
};

export const updateFinishedGood = async (id, data) => {
  return await api.put(`${endpoint}/${id}`, data);
};

export const deleteFinishedGood = async (id) => {
  return await api.delete(`${endpoint}/${id}`);
};

export const getFinishedGoodById = async (id) => {
  return await api.get(`${endpoint}/${id}`);
};
