import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

export const getUserRoles = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let decoded = jwtDecode(token);
    return { 
      user: decoded.given_name, 
      roles: decoded.roles ? JSON.parse(decoded.roles) : [],
      companyRoles: decoded.companyRoles ? JSON.parse(decoded.companyRoles) : []
    };
  }
  return { user: "", roles: [], companyRoles: [] };
};

export const isTokenExpired = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);
    return dayjs().isAfter(dayjs(_.exp * 1000));
  }
};

export const getUserCompany = () => {
  let token = localStorage.getItem("accessToken");
  if (token) {
    let _ = jwtDecode(token);
    return _.CompanyId;
  }
};
