import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import LGCrud from "../LGCrud";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import {
  createClient,
  getClientList,
  updateClient,
  deleteClient,
} from "../../api/client";

export default function CrudClient({ companyId }: { companyId: number }): JSX.Element {
  const { openSnack } = useSnack();

  const handlePost = async (data) => {
    const payload = {
      companyId,
      ...data,
    };
    try {
      const res = await createClient(payload);
      openSnack("Cliente guardado con éxito", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = {
      ...data,
    };
    try {
      const res = await updateClient(id, payload);
      openSnack("Cliente actualizado con éxito", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteClient(id);
  };

  return (
    <>
      <Box width={"100%"}>
        <LGCrud
          columns={[
            {
              field: "name",
              headerName: "Nombre",
              flex: 0.7,
              editable: true,
            },
            {
              field: "code",
              headerName: "Clave",
              flex: 0.3,
              editable: true,
            },
          ]}
          parentId={{
            name: "companyId",
            value: companyId,
          }}
          actionHandlers={{
            create: handlePost,
            update: handleUpdate,
            delete: handleDelete,
            fetchData: getClientList,
          }}
        />
      </Box>
    </>
  );
}
