import React, { useState } from "react";
import { deleteExport, getExportList, exportExports } from "../../api/exports";
import {
  Box,
  Button,
  Chip,
  Stack,
} from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import ExportForm from "./ExportForm";
import dayjs from "dayjs";
import { FileDownload } from "@mui/icons-material";
import LGModal from "../../molecules/LGModal";

export default function CrudExport({ companyId }) {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "CREADO":
        return "default";
      case "EN_PROCESO":
        return "warning"; 
      case "COMPLETADO":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Box width={"100%"}>
      <LGModal
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        header="Exportar a CSV"
      >
        {/* Add export modal content here if needed */}
      </LGModal>
      
      <Stack direction="row" spacing={2} mb={2}>
        <Button 
          onClick={() => setExportModalOpen(true)} 
          variant="contained" 
          startIcon={<FileDownload />}
        >
          Exportar CSV
        </Button>
      </Stack>

      <LGModalCrud
        props={{
          actions: ["create", "update", "delete", "detail"],
          data: { companyId },
          fetchFunction: getExportList,
          deleteFunction: deleteExport,
          columns: [
            {
              field: "code",
              headerName: "Código",
              flex: 0.2,
            },
            {
              field: "client",
              headerName: "Cliente",
              flex: 0.2,
              valueGetter: (value, row) => {
                return row.client ? `${row.client.code}` : "";
              },
            },
            {
              field: "purchaseOrder",
              headerName: "Orden de Compra",
              flex: 0.2,
            },
            {
              field: "packingListDate",
              headerName: "Fecha Packing List",
              flex: 0.2,
              valueGetter: (value, row) => {
                return dayjs(row.packingListDate).format("DD/MM/YYYY");
              }
            },
            {
              field: "quantity",
              headerName: "Cantidad",
              flex: 0.1,
            },
            {
              field: "status",
              headerName: "Estatus",
              flex: 0.2,
              renderCell: (params) => (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    size="small"
                    label={params.row.status.name}
                    color={setStatusColor(params.row.status.code)}
                  />
                </Box>
              ),
            },
          ],
          addModalData: {
            header: "Agregar Exportación",
            body: <ExportForm companyId={companyId} />,
          },
          editModalData: {
            header: "Editar Exportación", 
            body: <ExportForm companyId={companyId} />,
          },
        }}
      />
    </Box>
  );
}
