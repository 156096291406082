import {
  downloadProviderContractFile,
  getProviderContractsList,
} from "../../../api/providers";
import { Box } from "@mui/material";
import LGModalCrud from "../../LGCrud/LGModalCrud";
import FileUpload from "../../../screens/Lab/mockAddFile";
import ContractForm from "../ContractForm";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";

export default function CrudProviderContract({ props }) {
  const { id } = props;
  const { openSnack } = useSnack();

  const handleFileDownload = async (id) => {
    try {
      const response = await downloadProviderContractFile(id);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "downloadedFile";
      console.log(contentDisposition);

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        // Extract filename from Content-Disposition header
        const matches = contentDisposition.match(/filename="([^"]*)"/);
        if (matches && matches[1]) {
          fileName = matches[1];
        }
      }

      // Get the file extension from the filename if not available in content-disposition
      const fileExtension = fileName.split(".").pop();
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      // Set the download attribute with the filename including extension
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <>
      <Box width={"100%"}>
        {id && (
          <LGModalCrud
            props={{
              actions: ["create", "download"],
              data: { providerId: id },
              fetchFunction: getProviderContractsList,
              downloadFunction: handleFileDownload,
              detailMenu: [
                {
                  label: "Descargar",
                  handler: handleFileDownload,
                  header: "Descargar",
                  body: <FileUpload />,
                },
              ],
              columns: [
                {
                  field: "contractName",
                  headerName: "Nombre del documento",
                  flex: 0.5,
                },
              ],
              addModalData: {
                header: "Agregar Documento",
                body: <ContractForm providerId={id} />,
              },
              editModalData: {
                header: "Editar Proveedor",

              },
            }}
          />
        )}
      </Box>
    </>
  );
}
