import { api } from "..";

const endpoint = "/api/products";

// PRODUCTS
export const getProductList = async (params) => {
  const { code, ExpenseAccountIds, companyId } = params;

  const queryParams = new URLSearchParams();

  if (code) queryParams.append("code", code);

  if (ExpenseAccountIds && ExpenseAccountIds.length > 0) {
    ExpenseAccountIds.forEach((id) =>
      queryParams.append("ExpenseAccountIds", id)
    );
  }

  queryParams.append("companyId", companyId);

  const response = await api.get(`/api/products?${queryParams.toString()}`);
  return response;
};

export const getProductById = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addProduct = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateProduct = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteProduct = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

// PRODUCT CATEGORIES
export const getProductCategoryList = () => {
  return api.get(`${endpoint}/categories`);
};

export const getProductCategoryById = (id) => {
  return api.get(`${endpoint}/categories/${id}`);
};

export const addProductCategory = (data) => {
  return api.post(`${endpoint}/categories`, data);
};

export const updateProductCategory = (id, data) => {
  return api.put(`${endpoint}/categories/${id}`, data);
};

export const deleteProductCategory = (id) => {
  return api.delete(`${endpoint}/categories/${id}`);
};
  
