import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function LGSelectCellEditor({
  id,
  value,
  field,
  api,
  dropdownData,
  labelTemplate = (v) => v.name,
}) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    const selectedType = dropdownData.find(
      (type) => type.id === event.target.value
    );
    setSelectedValue(selectedType.id);
    api.setEditCellValue({ id, field, value: selectedType, debounceMs: 200 });
  };

  return (
    <Select value={selectedValue} onChange={handleChange} fullWidth>
      {dropdownData &&
        dropdownData.map((type) => (
          <MenuItem key={type.id} value={type.id}>
            {labelTemplate(type)}
          </MenuItem>
        ))}
    </Select>
  );
}
