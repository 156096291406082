// File: src/components/BankAccountItem/ForeignBankItem.jsx
import React from "react";
import { Grid, Icon, Stack, Typography } from "@mui/material";

const ForeignBankItem = ({ swift, aba, routingAba }) => (
  <>
    {swift && (
      <Grid container item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-around"
        >
          <Icon>swap_horiz</Icon>
          <Typography variant="button" color="primary.main">
            SWIFT
          </Typography>
          <Typography>{swift}</Typography>
        </Stack>
      </Grid>
    )}

    {aba && (
      <Grid container item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-around"
        >
          <Icon>baseline_sync_alt</Icon>
          <Typography variant="button" color="primary.main">
            ABA
          </Typography>
          <Typography>{aba}</Typography>
        </Stack>
      </Grid>
    )}

    {routingAba && (
      <Grid container item xs={12}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="space-around"
        >
          <Icon>outlined_flag</Icon>
          <Typography variant="button" color="primary.main">
            Routing ABA
          </Typography>
          <Typography>{routingAba}</Typography>
        </Stack>
      </Grid>
    )}
  </>
);

export default ForeignBankItem;
