export const companyThemes = {
  1: {
    primary: {
      main: "#025373",
      dark: "#022840",
    },
    secondary: {
      main: "#037F8C",
      dark: "#025E73",
    },
  },
  2: {
    primary: {
      main: "#0b3e82",
      dark: "#0a1f54",
    },
    secondary: {
      main: "#a7ddf2",
      dark: "#1c5bae",
    },
  },
  3: {
    primary: {
      main: "#D06257",
      dark: "#B15052",
    },
    secondary: {
      main: "#722B47",
      dark: "#34073D",
    },
  },
};
