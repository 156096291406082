import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useSnack } from "../../services/SnakbarProvider";
import RAHeader from "../../molecules/ReceiptAcknowledgements/RAHeader";
import RAItems from "../../molecules/ReceiptAcknowledgements/RAItems";
import { Button, Divider, Stack, Typography } from "@mui/material";
import RASummary from "../../molecules/ReceiptAcknowledgements/RASummary";
import {
  addReceiptAcknowledgement,
  getReceiptAcknowledgementDetail,
  updateReceiptAcknowledgement,
} from "../../api/reciptAcknowledgement";
import { handleError } from "../../services/errorHandler";
import { getProviderDetail } from "../../api/providers";

const ReceiptAcknowledgementForm = ({ id, onSubmit, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const { openSnack } = useSnack();

  const initialValues = {
    selectedProvider: "",
    selectedPurchaseOrder: "",
    receiptAcknowledgement: {
      companyId,
      purchaseOrderId: "",
      providerId: "",
      raTypeId: "",
      invoiceNo: "",
      invoiceDate: "",
      paymentDate: "",
      beneficiary: "",
      subtotal: 0,
      taxes: 0,
    },
    items: [
      {
        expenseAccountId: "",
        providerAccountId: "",
        amount: 0,
        taxRate: 0,
      },
    ],
  };

  const [formData, setFormData] = useState(initialValues);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getReceiptAcknowledgementDetail(id);
      const providerData = await getProviderDetail(
        res.data.providerId,
        res.data.companyId
      );
      const payload = {
        receiptAcknowledgement: res.data,
        items: res.data.items,
        selectedProvider: providerData.data,
      };

      setFormData(payload);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      if (formMode == "insert") {
        let payload = { ...values };
        payload.receiptAcknowledgement.companyId = companyId;
        const submitForm = await addReceiptAcknowledgement(payload);
        if (submitForm.status == 200) {
          openSnack("Contrarrecibo guardado exitosamente", "success");
        }
      } else if (formMode == "edit") {
        let payload = { ...values };
        const submitForm = await updateReceiptAcknowledgement(id, payload);
        if (submitForm.status == 200) {
          openSnack("Contrarrecibo actualizado exitosamente", "success");
        }
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return (
    <Formik
      initialValues={{ ...formData }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue, touched, errors }) => {
        // Calculate subtotal dynamically
        const calculatedSubtotal = values.items.reduce(
          (sum, item) => sum + (parseFloat(item.amount) || 0),
          0
        );

        const calculatedTaxes = values.items.reduce(
          (sum, item) =>
            sum + (parseFloat((item.amount * item.taxRate) / 100) || 0),
          0
        );

        // Update Formik's state if subtotal changes
        if (values.receiptAcknowledgement.subtotal !== calculatedSubtotal) {
          setFieldValue("receiptAcknowledgement.subtotal", calculatedSubtotal);
        }

        if (values.receiptAcknowledgement.taxes !== calculatedTaxes) {
          setFieldValue("receiptAcknowledgement.taxes", calculatedTaxes);
        }

        return (
          <Form>
            <Stack
              direction={"column"}
              spacing={2}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack direction={"column"}>
                <Typography variant="h4" mb={2}>
                  Información del contrarrecibo
                </Typography>
                <RAHeader
                  values={values}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  errors={errors}
                />
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h4">Detalle</Typography>
                <RAItems />
              </Stack>
              <Stack direction={"column"} spacing={2}>
                <Typography variant="h4">Resumen</Typography>
                <RASummary
                  subtotal={values.receiptAcknowledgement.subtotal}
                  taxes={values.receiptAcknowledgement.taxes}
                />
              </Stack>
            </Stack>
            <Button sx={{ mt: 2 }} type="submit" variant="contained">
              {formMode === "edit" ? "Actualizar" : "Añadir"}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReceiptAcknowledgementForm;
