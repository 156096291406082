import React, { useEffect, useState } from "react";
import LGCrud from "../LGCrud";
import {
  addEconomicActivity,
  deleteEconomicActivity,
  getEconomicActivityList,
  updateEconomicActivity,
} from "../../api/accounting";
import { getCatalogue } from "../../api/common/catalogues";
import LGSelectCellRenderer from "../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../LGCrud/LGSelectCellEditor";

export default function CrudEconomicActivity() {
  const [economicActivitiesList, setEconomicActivitiesList] = useState([]);
  const [psTypes, setPsTypes] = useState([]);

  const fetchPsTypes = async () => {
    const res = await getCatalogue({ CatalogueName: "PRODUCT_SERVICE_TYPE" });
    setPsTypes(res.data);
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.productServiceTypeId = payload.productServiceType.id;
    const res = await addEconomicActivity(payload);
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    payload.productServiceTypeId = payload.productServiceType.id;
    const res = await updateEconomicActivity(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteEconomicActivity(id);
  };

  useEffect(() => {
    fetchPsTypes();
  }, []);

  return (
    <LGCrud
      data={economicActivitiesList}
      columns={[
        {
          field: "productServiceType",
          headerName: "Producto o servicio",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v.code} | ${v.name}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={psTypes}
              labelTemplate={(v) => `${v.code} | ${v.name}`}
            />
          ),
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
        {
          field: "contpaqiPrefixCode",
          headerName: "Código",
          flex: 0.5,
          editable: true,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
        fetchData: getEconomicActivityList,
      }}
    />
  );
}
