import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import { useCompany } from "../../contexts/CompanyContext";
import CrudPayment from "../../organisms/Payments/CrudPayment";
import { getStatusCatalogue } from "../../api/common/catalogues";

export default function PaymentScreen() {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompany } = useCompany();
  const [paymentPaidStatusId, setPaymentPaidStatusId] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchPaymentPaidStatusId = async () => {  
    const paymentStatusList = await getStatusCatalogue("PAYMENT");
    setPaymentPaidStatusId(
      paymentStatusList.data.find((status) => status.code === "PAYMENT_PAID").id
    );
  };

  useEffect(() => {
    fetchPaymentPaidStatusId();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label={`Pagos Pendientes ${selectedCompany.name}`} />
          <Tab label={`Pagos Realizados ${selectedCompany.name}`} />
        </Tabs>
      </Box>

      <Box p={4}>
        <LGTabPanel value={activeTab} index={0}>
          <CrudPayment
            props={{
              companyId: selectedCompany.id,
              statusCode: "PAYMENT_SCHEDULED",
            }}
          />
        </LGTabPanel>
        <LGTabPanel value={activeTab} index={1}>
          <CrudPayment
            props={{
              companyId: selectedCompany.id,
              statusCode: "PAYMENT_PAID",
            }}
          />
        </LGTabPanel>
      </Box>
    </DashboardLayout>
  );
}
