import React from 'react';
import { Grid, useTheme } from '@mui/material';
import MonthlyBarChart from '../../atoms/Charts/MonthlyBarChart';

const ShipmentReportCharts = ({ containersData, delayFeesData }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <MonthlyBarChart
          data={containersData}
          title="Contenedores Embarcados por Mes"
          yAxisLabel="Contenedores"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MonthlyBarChart
          data={delayFeesData}
          title="Cargos por Demora Mensuales"
          yAxisLabel="USD"
          color={theme.palette.error.main}
        />
      </Grid>
    </Grid>
  );
};

export default ShipmentReportCharts;