import React from 'react';
import { Box, Card, CardContent, Typography, Chip, Stack } from '@mui/material';
import dayjs from 'dayjs';

const CPOShipmentCard = ({ data }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack spacing={1}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Chip 
              label={`Envío No: ${data.shipmentNo}`}
              size="medium"
              color="primary"
              variant="filled"
            />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Cantidad enviada:
            </Typography>
            <Typography variant="body1">
              {data.shippedQuantity}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Fecha de envío:
            </Typography>
            <Typography variant="body1">
              {dayjs(data.shippedDate).format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CPOShipmentCard; 