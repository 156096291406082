import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import LGModalCrud from "../LGCrud/LGModalCrud";
import { deleteProduct, getProductList } from "../../api/products";
import AddProductForm from "./addProductForm";
import ProductForm from "./addProductForm";

export default function CrudProducts({ companyId }) {
  return (
    <Box width={"100%"}>
      <LGModalCrud
        props={{
          actions: ["create", "update", "delete"],
          data: { companyId },
          fetchFunction: getProductList,
          deleteFunction: deleteProduct,
          columns: [
            {
              field: "code",
              headerName: "Código",
              flex: 0.2,
            },
            {
              field: "description",
              headerName: "Descripción",
              flex: 0.2,
            },
            // {
            //   field: "brand",
            //   headerName: "Marca",
            //   flex: 0.2,
            // },
            // {
            //   field: "productType",
            //   headerName: "Tipo de productos",
            //   flex: 0.1,
            //   editable: false,
            //   renderCell: (params) => (
            //     <Chip
            //       size="small"
            //       label={params.row.productType.name}
            //       color={
            //         params.row.productType.name == "MÁQUINA"
            //           ? "primary"
            //           : params.row.productType.name == "Persona Moral"
            //           ? "success"
            //           : "secondary"
            //       }
            //     />
            //   ),
            // },
          ],
          addModalData: {
            header: "Agregar Producto",
            body: <AddProductForm companyId={companyId} />,
          },
          editModalData: {
            header: "Editar Producto",
            body: (
              // <Typography>EDITAR Producto</Typography>
              <ProductForm />
              // <UpdateProviderFromAccountForm
              //   props={{ handleUpdate: handleUpdate }}
              // />
            ),
          },
        }}
      />
    </Box>
  );
}
