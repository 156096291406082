import { createTheme } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";

export const LGtheme = createTheme({
  status: {
    embarcado: {
      main: yellow[600],
      contrastText: grey[900],
    },
  },
});
