import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import CPOShipmentList from '../../molecules/CPO/CPOShipmentList';
import CPOShipmentForm from '../../molecules/CPO/CPOShipmentForm';
import LGModal from '../../molecules/LGModal';
import { useSnack } from '../../services/SnakbarProvider';
import { handleError } from '../../services/errorHandler';
import { createCPOShipment, getCPOShipments } from '../../api/clientPurchaseOrders';

const CPOShipments = ({ props }) => {
  const { id } = props;
  const [shipments, setShipments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { openSnack } = useSnack();

  const handleSubmit = async (values) => {
    try {
      const payload = { ...values };    
      payload.clientPurchaseOrderId = id;
      payload.shippedDate = values.shippedDate.format('YYYY-MM-DD');
      const res = await createCPOShipment(payload);  
      openSnack('Envío guardado exitosamente', 'success');
      setIsModalOpen(false);
      fetchData();
    } catch (e) {
      openSnack(handleError(e), 'error');
    }
  };

  const fetchData = async () => {
    try {
      const res = await getCPOShipments({ clientPurchaseOrderId: id });
      setShipments(res.data);
    } catch (e) {
      console.log(e);
    }
  };    

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Envíos</Typography>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => setIsModalOpen(true)}
          >
            Nuevo Envío
          </Button>
        </Grid>
        <Grid item xs={12}>
          <CPOShipmentList shipments={shipments} />
        </Grid>
      </Grid>

      <LGModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        header="Nuevo Envío"
      >
        <CPOShipmentForm onSubmit={handleSubmit} />
      </LGModal>
    </Box>
  );
};

export default CPOShipments; 