import { Box, Grid, useTheme } from "@mui/material";

const ENV = process.env.REACT_APP_ENV;

export default function DrawerHeader({ children }) {
  const theme = useTheme();
  const getEnvColor = () => {
    if (ENV === "LOCAL") {
      return theme.palette.purple?.[700] || "#34073D";
    } else if (ENV === "QA") {
      return "#FF8700";
    }
    return theme.palette.primary.main;
  };

  return (
    <Grid
      container
      direction={"row"}
      alignContent={"center"}
      justifyContent={"flex-end"}
      height={"64px"}
      py={4}
      sx={{
        backgroundColor: getEnvColor(),
      }}
    >
      <Grid item>{children}</Grid>
    </Grid>
  );
}
