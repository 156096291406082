import { ThemeProvider, createTheme } from '@mui/material';
import { CompanyProvider } from './contexts/CompanyContext';
import { useCompany } from './contexts/CompanyContext';
import { companyThemes } from './theme/companyThemes';
import logo from "./logo.svg";
import "./App.css";
import LoginScreen from "./screens/LoginScreen";
import { Navigate, Route, Router, Routes, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Switch } from "@mui/material";
import CompanyScreen from "./screens/Enterprise/Company";
import { getUserRoles } from "./services/auth";
import PlantList from "./organisms/Enterprise/PlantList";
import routes from "./services/routes";
import { UserProvider } from './context/UserContext';

function ThemedApp() {
  const { selectedCompany } = useCompany();
  
  const theme = createTheme({
    palette: companyThemes[selectedCompany?.id] || companyThemes[1]
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {routes.map((r) => (
          <Route path={r.path} exact element={r.element} key={r.path} />
        ))}
        <Route path="*" element={<LoginScreen />} />
      </Routes>
    </ThemeProvider>
  );
}

function App() {
  return (
    <UserProvider>
      <CompanyProvider>
        <ThemedApp />
      </CompanyProvider>
    </UserProvider>
  );
}

export default App;
