import { api } from "..";

const endpoint = "/api/catalogues";

// GET
export const getCatalogue = (queryParams) => {
  return api.get(`${endpoint}`, { params: queryParams });
};

export const getCatalogueEntryById = (id) => {
  return api.get(`${endpoint}/${id}`);
};

// POST
export const addCatalogueEntry = (data) => {
  return api.post(`${endpoint}`, data);
};

// PUT
export const updateCatalogueEntry = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

// DELETE
export const deleteCatalogueEntry = (id) => {
  return api.delete(`${endpoint}/${id}`);
};

// TAX REGIME
export const getTaxRegimeList = () => {
  return api.get(`${endpoint}/tax-regime/`);
};

export const addTaxRegime = (data) => {
  return api.post(`${endpoint}/tax-regime/`, data);
};

export const updateTaxRegime = (id, data) => {
  return api.put(`${endpoint}/tax-regime/${id}`, data);
};

export const deleteTaxRegime = (id) => {
  return api.delete(`${endpoint}/tax-regime/${id}`);
};

// SUBREGIME
export const getSubregimeList = () => {
  return api.get(`${endpoint}/subregime/`);
};

export const addSubregime = (data) => {
  return api.post(`${endpoint}/subregime/`, data);
};

export const updateSubregime = (id, data) => {
  return api.put(`${endpoint}/subregime/${id}`, data);
};

export const deleteSubregime = (id) => {
  return api.delete(`${endpoint}/subregime/${id}`);
};

// ADRESSES
export const addAddress = (data) => {
  return api.post(`/api/addresses/`, data);
};

export const getAddressById = (id) => {
  return api.get(`/api/addresses/${id}`);
};

export const updateAddress = (id, data) => {
  return api.put(`/api/addresses/${id}`, data);
};

// STATUS CATALOGUES
export const getStatusCatalogue = (CatalogueName) => {
  return api.get(`${endpoint}/status`, { params: { CatalogueName } });
};

export const addStatus = (data) => {
  return api.post(`${endpoint}/status`, data);
};

export const updateStatus = (id, data) => {
  return api.put(`${endpoint}/status/${id}`, data);
};

export const deleteStatus = (id) => {
  return api.delete(`${endpoint}/status/${id}`);
};
