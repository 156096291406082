import { api } from "..";
const endpoint = "/api/payments";

export const getPaymentList = async (queryParams) => {
  return await api.get(`${endpoint}`, { params: queryParams });
};

export const createPayment = async (data) => {
  return await api.post(`${endpoint}`, data);
};

export const updatePayment = async (id, data) => {
  return await api.put(`${endpoint}/${id}`, data);
};

export const deletePayment = async (id) => {
  return await api.delete(`${endpoint}/${id}`);
};

export const getPaymentById = async (id) => {
  return await api.get(`${endpoint}/${id}`);
};

export const payPayment = async (paymentIds) => {
  return await api.post(`${endpoint}/pay`, paymentIds);
};

export const exportPaymentLayout = async (paymentIds) => {
  return await api.post(`${endpoint}/export-payment-layout`, paymentIds);
};

export const exportPaymentCSV = async (paymentIds) => {
  return await api.post(`${endpoint}/export-csv`, paymentIds);
};
