import { api } from "..";
const endpoint = "/api/exports";

export const getExportList = async (queryParams) => {
  return await api.get(`${endpoint}`, { params: queryParams });
};

export const createExport = async (data) => {
  return await api.post(`${endpoint}`, data);
};

export const updateExport = async (id, data) => {
  return await api.put(`${endpoint}/${id}`, data);
};

export const deleteExport = async (id) => {
  return await api.delete(`${endpoint}/${id}`);
};

export const getExportById = async (id) => {
  return await api.get(`${endpoint}/${id}`);
};
