import { useEffect } from "react";
import LoginForm from "../../organisms/Auth/LoginForm";
import { Avatar, Box, Container, CssBaseline } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserRoles } from "../../services/auth";

export default function LoginScreen() {
  const navigate = useNavigate();
  const userData = getUserRoles();

  useEffect(() => {
    if (userData.user != "") {
      navigate("/home/");
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          sx={{ m: 1, width: 64, height: 64 }}
          variant="square"
          src={`${process.env.PUBLIC_URL}/assets/Logo-LG-2.png`}
        ></Avatar>

        <LoginForm />
      </Box>
    </Container>
  );
}
