import React from "react";
import { Field, Form, Formik, FastField, FormikHelpers } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  TextFieldProps,
} from "@mui/material";
import { addProviderContact } from "../../../api/providers";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";
import { getCatalogue } from "../../../api/common/catalogues";
import DropdownSelector from "../../../atoms/Common/DropdownSelector";

// Add interfaces for the component props and form values
interface AddContactFormProps {
  providerId: number;
  onSubmit: () => void;
}

interface ContactFormValues {
  providerId: string;
  name: string;
  contactTypeId: string;
  email: string;
  phone: string;
  mobile: string;
  ext: string;
  acceptsCreceipts: boolean;
}

const initialValues: ContactFormValues = {
  providerId: "",
  name: "",
  contactTypeId: "",
  email: "",
  phone: "",
  mobile: "",
  ext: "",
  acceptsCreceipts: false,
};

// Validation schema
const validationSchema = yup.object({
  name: yup.string().required("El nombre es obligatorio"),
  contactTypeId: yup.number().integer(),
  email: yup.string().email("Ingrese un correo electrónico válido"),
  phone: yup.string().required("El teléfono es obligatorio"),
  mobile: yup.string().required("El teléfono móvil es obligatorio"),
  ext: yup.string().required("La extensión es obligatoria"),
  acceptsCreceipts: yup.boolean(),
});

export default function AddContactForm({
  providerId,
  onSubmit,
}: AddContactFormProps) {
  const { openSnack } = useSnack();

  const handleSubmit = async (
    values: ContactFormValues,
    { setSubmitting }: FormikHelpers<ContactFormValues>
  ) => {
    const payload = { ...values, providerId };
    try {
      await addProviderContact(payload);
      openSnack("El contacto se guardó exitosamente", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    onSubmit();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Field
            as={TextField}
            fullWidth
            id="name"
            name="name"
            label="Nombre del contacto"
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
            margin="normal"
          />
          <FastField
            as={DropdownSelector}
            fullWidth
            fetchFunction={getCatalogue}
            fetchParams={{ catalogueName: "CONTACT_TYPE" }}
            id="contactTypeId"
            name="contactTypeId"
            label="Tipo de Contacto"
            error={touched.contactTypeId && Boolean(errors.contactTypeId)}
            helperText={touched.contactTypeId && errors.contactTypeId}
            margin="normal"
          />
          <Field
            as={TextField}
            fullWidth
            id="email"
            name="email"
            label="Correo Electrónico"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            margin="normal"
          />
          <Field
            as={TextField}
            fullWidth
            id="phone"
            name="phone"
            label="Teléfono"
            error={touched.phone && Boolean(errors.phone)}
            helperText={touched.phone && errors.phone}
            margin="normal"
          />
          <Field
            as={TextField}
            fullWidth
            id="mobile"
            name="mobile"
            label="Teléfono Móvil"
            error={touched.mobile && Boolean(errors.mobile)}
            helperText={touched.mobile && errors.mobile}
            margin="normal"
          />
          <Field
            as={TextField}
            fullWidth
            id="ext"
            name="ext"
            label="Extensión"
            error={touched.ext && Boolean(errors.ext)}
            helperText={touched.ext && errors.ext}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Field
                as={Checkbox}
                id="acceptsCreceipts"
                name="acceptsCreceipts"
              />
            }
            label="¿Acepta contrarecibo?"
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            sx={{ mt: 2 }}
          >
            Guardar
          </Button>
        </Form>
      )}
    </Formik>
  );
}
