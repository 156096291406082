import { MenuItem, Select } from "@mui/material";

const MonthPicker = ({ value, onChange }) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
    "Todos",
  ];

  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
    >
      {months.map((month, index) => (
        <MenuItem key={month} value={index}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MonthPicker;