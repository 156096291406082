import React from "react";
import { Grid, TextField } from "@mui/material";
import { Field } from "formik";
import DropdownSelector from "../../../atoms/Common/DropdownSelector";
import { getCurrencyList } from "../../../api/common/currencies";

const ForeignBankForm = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field
        name="foreignBank"
        as={TextField}
        label="Banco Extranjero"
        fullWidth
      />
    </Grid>
    <Grid item xs={8}>
      <Field
        name="bankAccount"
        as={TextField}
        label="Cuenta Bancaria"
        fullWidth
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        name="currencyId"
        as={DropdownSelector}
        fetchFunction={getCurrencyList}
        optionLabel={"symbol"}
        label="Moneda"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <Field name="swift" as={TextField} label="SWIFT" fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Field name="aba" as={TextField} label="ABA" fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Field name="routingAba" as={TextField} label="Routing ABA" fullWidth />
    </Grid>
  </Grid>
);

export default ForeignBankForm;
