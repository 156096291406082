import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCompanyList } from '../api/enterprise';
import { getUserCompany, getUserRoles } from '../services/auth';

const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const res = await getCompanyList();
        const userData = getUserRoles();
        
        // Filter companies based on user's company roles
        const userCompanyIds = userData.companyRoles.map(cr => cr.CompanyId);
        const filteredCompanies = res.data.filter(company => 
          userCompanyIds.includes(company.id)
        );
        
        setCompanies(filteredCompanies);
        
        // Set initial company from user's default
        const userCompany = await getUserCompany();
        let initialCompany = filteredCompanies.find(c => c.id === userCompany) || filteredCompanies[0];
        
        // Load saved company ID from localStorage if it exists
        const savedCompanyId = localStorage.getItem('selectedCompanyId');
        if (savedCompanyId) {
          // Verify the saved company is in user's allowed companies
          const existingCompany = filteredCompanies.find(c => c.id === parseInt(savedCompanyId));
          if (existingCompany) {
            initialCompany = existingCompany;
          }
        }

        if (initialCompany) {
          localStorage.setItem('selectedCompanyId', initialCompany.id);
          setSelectedCompany(initialCompany);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <CompanyContext.Provider value={{ selectedCompany, setSelectedCompany, companies, loading }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => useContext(CompanyContext); 