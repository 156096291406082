import React from 'react';
import { BarChart } from '@mui/x-charts';
import { Box, Typography, useTheme } from '@mui/material';

const MonthlyBarChart = ({ data, title, yAxisLabel, color }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <Typography variant="h6" align="center" gutterBottom>
        {title}
      </Typography>
      <BarChart
        dataset={data}
        xAxis={[{ 
          scaleType: 'band', 
          dataKey: 'month',
          label: 'Mes'
        }]}
        series={[{ 
          dataKey: 'value',
          label: yAxisLabel,
          valueFormatter: (value) => value?.toLocaleString() || 0,
          color: color || theme.palette.primary.main
        }]}
        height={350}
      />
    </Box>
  );
};

export default MonthlyBarChart; 