import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import CrudProviderAccounts from "../../organisms/Providers/AccountList/CrudProviderAccounts";
import { useCompany } from "../../contexts/CompanyContext";


export default function ProviderScreen(): JSX.Element {
  const [activeTab, setActiveTab] = useState(0);
  const { selectedCompany } = useCompany();

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={activeTab} onChange={handleChange}>
          <Tab label={`Proveedores ${selectedCompany.name}`} />
        </Tabs>
      </Box>

      <Box p={4}>
        <LGTabPanel value={activeTab} index={0}>
          <CrudProviderAccounts props={{ companyId: selectedCompany.id }} />
        </LGTabPanel>
      </Box>
    </DashboardLayout>
  );
}
