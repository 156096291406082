import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUserRoles } from '../services/auth';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userData, setUserData] = useState(getUserRoles());

  const updateUserData = () => {
    setUserData(getUserRoles());
  };

  return (
    <UserContext.Provider value={{ userData, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => useContext(UserContext); 