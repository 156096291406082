import React, { useEffect, useState } from "react";
import CompanyList from "../../../organisms/Enterprise/CompanyList";
import { getCompanyList, getPlantList } from "../../../api/enterprise";
import { Box, Typography } from "@mui/material";
import PlantList from "../../../organisms/Enterprise/PlantList";
import DashboardLayout from "../../../layouts/DashboardLayout";

export default function CompanyScreen() {
  const [companyList, setCompanyList] = useState([]);
  const [plantList, setPlantList] = useState([]);

  const fetchData = async () => {
    const resC = await getCompanyList();
    if (resC.data) {
      setCompanyList(resC.data);
    }

    const resP = await getPlantList();
    if (resP.data) {
      setPlantList(resP.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DashboardLayout>
        <Box m={4} p={4}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ textTransform: "uppercase" }}
          >
            Catálogo de empresas
          </Typography>
          <CompanyList data={companyList} />

          <Box m={4} />
          <Typography
            variant="h4"
            component="h1"
            sx={{ textTransform: "uppercase" }}
          >
            Catálogo de plantas
          </Typography>
          <PlantList data={plantList} />
        </Box>
      </DashboardLayout>
    </>
  );
}
