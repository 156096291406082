import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Container, Grid } from "@mui/material";
import {
  addAddress,
  getAddressById,
  updateAddress,
} from "../../../api/common/catalogues";
import { useSnack } from "../../../services/SnakbarProvider";
import { handleError } from "../../../services/errorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es obligatorio"),
  street: Yup.string().required("La calle es obligatoria"),
  outNum: Yup.string().required("El número exterior es obligatorio"),
  innNum: Yup.string(),
  colony: Yup.string().required("La colonia es obligatoria"),
  municipality: Yup.string().required("El municipio es obligatorio"),
  country: Yup.string().required("El país es obligatorio"),
  state: Yup.string().required("El estado es obligatorio"),
  cp: Yup.string()
    .required("El código postal es obligatorio")
    .matches(/^\d{5}$/, "El código postal debe tener 5 dígitos"),
});

const initialValues = {
  name: "",
  street: "",
  outNum: "",
  innNum: "",
  colony: "",
  municipality: "",
  country: "",
  state: "",
  cp: "",
};

const AddressForm = ({ props }) => {
  const id = props ? props.id : 0;
  const { onSubmit } = props;
  const [address, setAddress] = useState(initialValues);
  const { openSnack } = useSnack();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: address,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let req;
      try {
        // NUEVA DIRECCION
        onSubmit(values);
        // if (id == 0) {
        //   req = await addAddress(values);
        //   onSubmit(req.data.id);
        //   // EDITAR DIRECCION
        // } else {
        //   req = await updateAddress(id, values);
        // }
        openSnack("Direccion actualizada exitosamente", "success");
      } catch (e) {
        openSnack(`Error en guardar la dirección: ${handleError(e)}`, "error");
      }
    },
  });

  const fetchData = async () => {
    const res = await getAddressById(id);
    if (res.data) {
      setAddress(res.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              name="name"
              label="Nombre"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              id="street"
              name="street"
              label="Calle"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outNum"
              name="outNum"
              label="No. Ext."
              value={formik.values.outNum}
              onChange={formik.handleChange}
              error={formik.touched.outNum && Boolean(formik.errors.outNum)}
              helperText={formik.touched.outNum && formik.errors.outNum}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="innNum"
              name="innNum"
              label="No. Int"
              value={formik.values.innNum}
              onChange={formik.handleChange}
              error={formik.touched.innNum && Boolean(formik.errors.innNum)}
              helperText={formik.touched.innNum && formik.errors.innNum}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              id="colony"
              name="colony"
              label="Colonia"
              value={formik.values.colony}
              onChange={formik.handleChange}
              error={formik.touched.colony && Boolean(formik.errors.colony)}
              helperText={formik.touched.colony && formik.errors.colony}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="cp"
              name="cp"
              label="C.P."
              value={formik.values.cp}
              onChange={formik.handleChange}
              error={formik.touched.cp && Boolean(formik.errors.cp)}
              helperText={formik.touched.cp && formik.errors.cp}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="municipality"
              name="municipality"
              label="Municipio"
              value={formik.values.municipality}
              onChange={formik.handleChange}
              error={
                formik.touched.municipality &&
                Boolean(formik.errors.municipality)
              }
              helperText={
                formik.touched.municipality && formik.errors.municipality
              }
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="state"
              name="state"
              label="Estado"
              value={formik.values.state}
              onChange={formik.handleChange}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="country"
              name="country"
              label="País"
              value={formik.values.country}
              onChange={formik.handleChange}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
            />
          </Grid>

          <Grid item xs={12}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Enviar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddressForm;
