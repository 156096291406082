import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box,
  Chip,
  Stack,
  Divider 
} from '@mui/material';
import dayjs from 'dayjs';

const PurchaseOrderCard = ({ purchaseOrder, onApprove }) => {
  const {
    code,
    provider,
    total,
    createdAt,
    currency,
    createdBy,
    items = []
  } = purchaseOrder;

  // Extract unique work order IDs from items
  const workOrders = [...new Set(items
    .map(item => item.workOrderId)
    .filter(Boolean))];

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: currency?.symbol || 'MXN'
    }).format(amount);
  };

  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start">
          <Box>
            <Typography variant="subtitle1" color="text.secondary">
              {provider?.code}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {dayjs(createdAt).format('DD/MM/YY')}
            </Typography>
          </Box>
          
          <Box textAlign="right">
            <Typography variant="h5" component="div">
              {formatCurrency(total)}
            </Typography>
            <Typography color="text.secondary">
              {currency?.symbol || 'MXN'}
            </Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography variant="body2" color="text.secondary">
            PO: {code}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Comprador: {createdBy}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant="body2" color="text.secondary" gutterBottom>
          Órdenes de trabajo:
        </Typography>
        <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mb: 2 }}>
          {workOrders.map((wo, index) => (
            <Chip 
              key={index}
              label={wo}
              size="small"
              variant="outlined"
            />
          ))}
        </Stack>

        <Box mt="auto" display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="success"
            size="large"
            onClick={onApprove}
          >
            APROBAR
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PurchaseOrderCard;