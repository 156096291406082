import React from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ClientPurchaseOrderCrud from "../../organisms/CPO/ClientPurchaseOrderCrud";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useCompany } from "../../contexts/CompanyContext";

const ClientPurchaseOrderScreen = () => {
  const location = useLocation();
  const { selectedCompany } = useCompany();

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <ClientPurchaseOrderCrud companyId={selectedCompany.id} />
    </DashboardLayout>
  );
};

export default ClientPurchaseOrderScreen;
