import { api } from "..";
const endpoint = "/api/clients";

export const getClientList = async (queryParams) => {
  return await api.get(`${endpoint}`, { params: queryParams });
};

export const createClient = async (data) => {
  return await api.post(`${endpoint}`, data);
};

export const updateClient = async (id, data) => {
  return await api.put(`${endpoint}/${id}`, data);
};

export const deleteClient = async (id) => {
  return await api.delete(`${endpoint}/${id}`);
};

export const getClientById = async (id) => {
  return await api.get(`${endpoint}/${id}`);
};
