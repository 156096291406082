import React, { useEffect, useState } from "react";
import LGCrud from "../LGCrud";
import dayjs from "dayjs";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import {
  addProductCategory,
  deleteProductCategory,
  getProductCategoryList,
  updateProductCategory,
} from "../../api/products";
import LGSelectCellRenderer from "../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../LGCrud/LGSelectCellEditor";
import { getCatalogue } from "../../api/common/catalogues";

export default function CrudProductCategories() {
  const { openSnack } = useSnack();
  const [productTypes, setProductTypes] = useState([]);

  const fetchProductTypes = async () => {
    const res = await getCatalogue({ CatalogueName: "PRODUCT_TYPE" });
    setProductTypes(res.data);
  };

  useEffect(() => {
    fetchProductTypes();
  }, []);

  const handlePost = async (data) => {
    const payload = {
      ...data,
      productTypeId: data.productType?.id
    };
    try {
      const res = await addProductCategory(payload);
      openSnack("Categoría de producto guardada con éxito", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleUpdate = async (id, data) => {
    const payload = {
      ...data,
      lastUpdate: dayjs(),
      productTypeId: data.productType?.id
    };
    try {
      const res = await updateProductCategory(id, payload);
      openSnack("Categoría de producto actualizada con éxito", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleDelete = async (id) => {
    const res = await deleteProductCategory(id);
  };

  return (
    <LGCrud
      columns={[
        {
          field: "productType",
          headerName: "Tipo de producto",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={productTypes}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
        },
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.7,
          editable: true,
        },
        {
          field: "code",
          headerName: "Código",
          flex: 0.3,
          editable: true,
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
        fetchData: getProductCategoryList,
      }}
    />
  );
}
