import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

export default function AddressCard({ address }) {
  return (
    <Card style={{ maxWidth: 400, margin: "auto", flex: 1, height: "100%" }}>
      <CardContent>
        <Typography variant="h6" component="div">
          {address.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {`${address.street} ${address.outNum}${
            address.innNum ? `, ${address.innNum}` : ""
          }`}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address.colony}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address.municipality}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {address.state}, {address.country}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          CP: {address.cp}
        </Typography>
      </CardContent>
    </Card>
  );
}
