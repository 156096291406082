import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

export default function RAExportModal({ companyId, onSubmit }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [codeStart, setCodeStart] = useState(null);
  const [codeEnd, setCodeEnd] = useState(null);


  const handleSubmit = () => {
    onSubmit({
      startDate: startDate?.format("YYYY-MM-DD"),
      endDate: endDate?.format("YYYY-MM-DD"),
      codeStart,
      codeEnd,
      companyId,
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Por fecha</Typography>
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha inicial"
            value={startDate}
            onChange={(value) => setStartDate(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <MyDatePicker
            fullWidth
            slotProps={{ textField: { fullWidth: true } }}
            label="Fecha final"
            value={endDate}
            onChange={(value) => setEndDate(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Por rango de código</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Código inicial"
            value={codeStart}
            onChange={(e) => setCodeStart(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Código final"
            value={codeEnd}
            onChange={(e) => setCodeEnd(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            // disabled={!startDate || !endDate || !codeStart || !codeEnd}
          >
            Exportar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
