import React from "react";
import { Modal, Typography, IconButton, Box, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const LGModal = ({ open, handleClose, header, children }) => {
  const theme = useTheme();
  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Paper
        sx={{
          outline: "none",
          maxWidth: 1200,
          width: "66%",
          maxHeight: "80vh", // Limit the maximum height of the modal
          overflow: "hidden", // Prevent overflow outside the modal
        }}
      >
        {/* Header */}
        <Box
          sx={{
            fontSize: "18pt",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          }}
          px={4}
          py={2}
          mb={2}
        >
          {header}
          <IconButton onClick={handleClose} color="eee5e9">
            <CloseIcon sx={{ color: theme.palette.primary.contrastText }} />
          </IconButton>
        </Box>

        {/* Body Section */}
        <Box
          sx={{
            p: 4,
            overflowY: "auto", // Enables vertical scrolling when content exceeds height
            maxHeight: "calc(80vh - 80px)", // Adjust according to the header height and padding
          }}
          id="modal-description"
        >
          {children}
        </Box>
      </Paper>
    </Modal>
  );
};

LGModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

export default LGModal;
