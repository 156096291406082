import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { esES } from "@mui/x-data-grid/locales";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { LGCrudToolbar } from "../../molecules/LGCrud/toolbar";
import LGCrudViewMode from "../../molecules/LGCrud/viewMode";
import LGCrudEditMode from "../../molecules/LGCrud/editMode";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import ConfirmDialog from "../../molecules/Common/ConfirmDialog";

export default function LGCrud(props) {
  const {columns, actionHandlers, catalogueName = null, parentId = null } = props;
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const { openSnack } = useSnack();
  const [selectedId, setSelectedId] = useState();

  // FILTER, SORT AND PAGINATION
  const [queryOptions, setQueryOptions] = useState({});
  const [queryParams, setQueryParams] = useState(catalogueName ? { catalogueName } : {});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 20,
  });

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
    const qp = filterModel.items.reduce((acc, item) => {
      if (item.value !== undefined && item.value !== "") {
        acc[item.field] = item.value;
      }
      return acc;
    }, {});
    setQueryParams({ ...queryParams, ...qp });
  });

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setOpenConfirm(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const res = await actionHandlers.delete(selectedId);
      setRows(rows.filter((row) => row.id !== selectedId));
      openSnack("Entrada borrada exitosamente", "success");
    } catch (e) {
      openSnack(handleError(e), "error");
    } finally {
      setOpenConfirm(false); // Close the dialog
    }
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const fetchData = async () => {
    try {
      console.log(queryParams);
      const response = await actionHandlers.fetchData(queryParams);
      setRows(response.data);
    } catch (error) {
      openSnack(handleError(error), "error");
    }
  };

  const processRowUpdate = async (newRow) => {
    const payload = { ...newRow };
    delete payload.id;

    // Add new
    if (newRow.isNew) {
      const res = await actionHandlers.create(payload);
    }
    // Edit Existing
    else {
      const res = await actionHandlers.update(newRow.id, payload);
    }

    const updatedRow = { ...newRow, isNew: false };

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processedColumns = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Acciones",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <LGCrudEditMode
              id={id}
              onSave={handleSaveClick}
              onCancel={handleCancelClick}
            />,
          ];
        }

        return [
          <LGCrudViewMode
            id={id}
            onEdit={() => handleEditClick(id)}
            onDelete={() => handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    fetchData();
  }, [queryParams, paginationModel]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      pageNumber: paginationModel.page + 1,
      pageSize: paginationModel.pageSize,
      ...(parentId && { [parentId.name]: parentId.value }),
    });
  }, [paginationModel]);

  return (
    <Box
      sx={{
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
      }}
    >
      <ConfirmDialog
        open={openConfirm}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDelete}
      />
      <DataGrid
        rows={rows}
        columns={processedColumns}
        getRowId={(row) => row.id}
        editMode="row"

        // SERVER FILTERING AND PAGINATION
        rowCount={-1}
        paginationMode="server"
        filterMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[5, 10, 20, 50]}
        onFilterModelChange={onFilterChange}

        // STYLE and i18n
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.log(error);
        }}
        slots={{
          toolbar: LGCrudToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}
