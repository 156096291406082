import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Stack, Typography } from "@mui/material";
import LGModal from "../../../molecules/LGModal";
import { getProviderBankDetailList } from "../../../api/providers";
import ProviderBankForm from "../ProviderBanks/ProviderBankForm";
import BankAccountItem from "../../../molecules/Providers/BankAccountItem/BankAccountItem";

export default function BankAccountList({ props }) {
  const { id } = props;
  const [openModal, setOpenModal] = useState(false);
  const [bankAccountList, setBankAccountList] = useState([]);

  const handleModalClose = () => {
    setOpenModal(false);
    fetchData();
  };

  const fetchData = async () => {
    const res = await getProviderBankDetailList(id);
    if (res.data) {
      setBankAccountList(res.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <LGModal
        open={openModal}
        header="Agregar Cuenta bancaria"
        handleClose={handleModalClose}
      >
        <ProviderBankForm id={id} onSubmit={handleModalClose} />
      </LGModal>
      <Grid container>
        <Grid item xs={9}>
          <Typography variant="h4">Información Bancaria</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<Icon>add</Icon>}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Agregar
          </Button>
        </Grid>
      </Grid>
      {bankAccountList.map((e, index) => (
        <BankAccountItem key={index} props={e} />
      ))}
    </>
  );
}
