import React from "react";
import { Typography } from "@mui/material";
import { formatCurrency } from "../../services/valueFormatters";

const RASummary = ({ subtotal, taxes }) => {
  return (
    <div>
      <Typography variant="h6">Subtotal: {formatCurrency(subtotal)}</Typography>
      <Typography variant="h6">Impuestos: {formatCurrency(taxes)}</Typography>
      <Typography variant="h5">
        Total: {formatCurrency(subtotal + taxes)}
      </Typography>
    </div>
  );
};

export default RASummary;
