import React from "react";
import { Field, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Grid,
  IconButton,
  Icon,
  Typography,
} from "@mui/material";
import { uploadShipmentFiles } from "../../api/imports";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const SUPPORTED_FORMATS = [
  "application/pdf",
  "application/xml",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "image/png",
  "image/jpeg",
  "application/zip",
];

const validationSchema = Yup.object().shape({
  files: Yup.array().of(
    Yup.mixed()
      .required("Aun no se ha seleccionado un archivo")
      .test(
        "fileFormat",
        "Formato no aceptado",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      )
  ),
});

const fileConcepts = [
  "Guia de embarque",
  "Lista de empaque",
  "Factura Proveedor",
  "Pedimento",
  "Cuenta de Gastos (ZIP)",
];

const initialValues = {
  files: [],
};

export default function FileUploadForm({ onSubmit, shipmentId }) {
  const { openSnack } = useSnack();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        metadata: fileConcepts.map((e) => [{ fileConcept: e, shipmentId }][0]),
      };
      try {
        const res = uploadShipmentFiles(
          JSON.stringify(payload.metadata),
          values.files
        );
        openSnack("Archivos subidos satisfactoriamente", "success");
        onSubmit();
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    },
  });

  return (
    <Box sx={{ margin: "0 auto", padding: "2rem" }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {fileConcepts.map((e, index) => (
            <>
              <Grid item xs={6}>
                <Typography variant="body1">{e}</Typography>
              </Grid>{" "}
              <Grid item xs={6}>
                <input
                  id={`files.${index}`}
                  name={`files.${index}`}
                  type="file"
                  onChange={(event) => {
                    formik.setFieldValue(
                      `files.${index}`,
                      event.currentTarget.files[0]
                    );
                  }}
                  onBlur={formik.handleBlur}
                  accept=".pdf, .xml, .docx, .xlsx, .png, .jpg, .zip"
                />
              </Grid>
            </>
          ))}

          <Grid item xs={12}>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
