import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import { useSnack } from "../../services/SnakbarProvider";
import ReceiptAcknowledgementCrud from "../../organisms/ReceiptAcknowledgement/ReceiptAcknowledgementCrud";
import { useCompany } from "../../contexts/CompanyContext";

export default function RAScreen() {
  const [value, setValue] = React.useState(0);
  const { selectedCompany } = useCompany();
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Box p={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ textTransform: "uppercase" }}
        >
          Lista de contrarrecibos
        </Typography>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Contrarrecibos" />
            {/* Add more tabs if needed */}
          </Tabs>
        </Box>
        <LGTabPanel value={value} index={0}>
          <ReceiptAcknowledgementCrud props={{ companyId: selectedCompany.id }} />
        </LGTabPanel>
      </Box>
    </DashboardLayout>
  );
}
