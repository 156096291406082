import React, { useEffect } from "react";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { useCompany } from "../contexts/CompanyContext";
import { useUser } from "../context/UserContext";

export default function CompanySelector() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selectedCompany, setSelectedCompany, companies, loading } = useCompany();
  const { updateUserData } = useUser();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanySelect = async (company) => {
    const companyChangeEvent = new CustomEvent("companyChange", {
      detail: {
        oldCompany: selectedCompany,
        newCompany: company,
      },
    });
    window.dispatchEvent(companyChangeEvent);

    localStorage.setItem("selectedCompanyId", company.id);
    setSelectedCompany(company);
    updateUserData();
    handleClose();
  };


  useEffect(() => {
    console.log("Company Changed");
  }, [selectedCompany]);


  return (
    <>
      <Button
        onClick={handleClick}
        color="primary"
        variant="contained"
        sx={{ textTransform: "none" }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {selectedCompany?.name || "Seleccionar Compañía"}
        </Box>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {companies.length > 0 ? (
          companies.map((company) => (
            <MenuItem
              key={company.id}
              onClick={() => handleCompanySelect(company)}
              selected={selectedCompany?.id === company.id}
            >
              {company.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No hay compañías disponibles</MenuItem>
        )}
      </Menu>
    </>
  );
}
