import React from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import { useFormikContext, FastField } from "formik";
import { formatCurrency } from "../../services/valueFormatters";

const PODetailHeader = () => {
  const { values } = useFormikContext();

  const subtotal = values.items.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );
  const tax = values.items.reduce(
    (acc, item) => acc + (item.quantity * item.price * item.taxRate) / 100,
    0
  );
  values.purchaseOrder.subtotal = subtotal;
  values.purchaseOrder.tax = tax;
  values.purchaseOrder.total =
    subtotal * (1 - values.purchaseOrder.discountPercentage / 100) +
    values.purchaseOrder.tax;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">
          Agregar Conceptos orden: {values.purchaseOrder.code}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Total</Typography>
      </Grid>
      <Grid item xs={2}>
        <FastField
          as={TextField}
          name="purchaseOrder.discountPercentage"
          label="Porcentaje Descuento"
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled
          name="discount"
          label="Descuento"
          value={formatCurrency(
            (values.purchaseOrder.discountPercentage *
              values.purchaseOrder.subtotal) /
              100
          )}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          disabled
          name="tax"
          label="Impuesto Total"
          value={formatCurrency(values.purchaseOrder.tax)}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          name="subtotal"
          label="Subtotal"
          value={formatCurrency(values.purchaseOrder.subtotal)}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <Box
          pt={1}
          pl={1}
          sx={{
            backgroundColor: "#AAFFAA",
            color: "#003300",
            outlineColor: "#66FF66",
          }}
          borderRadius={"4px"}
          height={"100%"}
        >
          <Typography variant="body2">TOTAL</Typography>
          <Typography variant="body1">
            {formatCurrency(values.purchaseOrder.total)}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(PODetailHeader);
