import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Button, Dialog, DialogActions, DialogContent, 
         DialogTitle, TextField, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSnack } from "../../services/SnakbarProvider";
import { getRoles } from "../../api/auth";
import { api } from "../../api";
import LGCrud from "../../organisms/LGCrud";

export default function RolesScreen() {
  const { openSnack } = useSnack();

  const handlePost = async (data) => {
    try {
      await api.post("/api/roles", data.name);
      openSnack("Rol creado exitosamente", "success");
    } catch (error) {
      openSnack(error.response?.data || "Error al crear rol", "error");
    }
  };

  const handleUpdate = async (id, data) => {
    try {
      await api.put(`/api/roles/${id}`, data);
      openSnack("Rol actualizado exitosamente", "success");
    } catch (error) {
      openSnack(error.response?.data || "Error al actualizar rol", "error");
    }
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/roles/${id}`);
      openSnack("Rol eliminado exitosamente", "success");
    } catch (error) {
      openSnack(error.response?.data || "Error al eliminar rol", "error");
    }
  };

  return (
    <DashboardLayout>
      <Box p={4}>
        <Typography variant="h4" mb={3}>Administración de Roles</Typography>
        
        <LGCrud
          columns={[
            {
              field: "name",
              headerName: "Nombre del Rol",
              flex: 1,
              editable: true,
            }
          ]}
          actionHandlers={{
            create: handlePost,
            update: handleUpdate,
            delete: handleDelete,
            fetchData: getRoles
          }}
        />
      </Box>
    </DashboardLayout>
  );
}