import React from "react";
import { Grid, Button, Icon } from "@mui/material";
import { FieldArray } from "formik";
import POItemRow from "../../molecules/PurchaseOrder/POItemRow";
import LGItemCellHeader from "../../atoms/Common/LGItemCellHeader";

const PODetailTable = ({ items, activityIds, companyId }) => (
  <Grid container rowSpacing={0} columnSpacing={0}>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Actividad"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Cantidad"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Unidad"} />
    </Grid>
    <Grid item xs={2}>
      <LGItemCellHeader label={"Concepto"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Producto"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Tipo de activo"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Destino"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Precio unitario"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Orden de trabajo"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Tasa IVA"} />
    </Grid>
    <Grid item xs={1}>
      <LGItemCellHeader label={"Eliminar"} color={"error.main"} />
    </Grid>
    <FieldArray name="items">
      {({ push, remove }) => (
        <>
          {items.length > 0 &&
            items.map((item, index) => (
              <Grid item xs={12} key={index}>
                <POItemRow
                  index={index}
                  remove={remove}
                  activityIds={activityIds}
                  companyId={companyId}
                />
              </Grid>
            ))}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                push({
                  quantity: 0,
                  price: 0,
                  productId: null,
                  unitId: "",
                  concept: "",
                  workOrderId: "",
                  taxRate: 16,
                })
              }
            >
              <Icon>add</Icon>
            </Button>
          </Grid>
        </>
      )}
    </FieldArray>
  </Grid>
);

export default React.memo(PODetailTable);
