import React from "react";
import { Box, Typography, Grid, Icon, Stack } from "@mui/material";
import NationalBankItem from "./NationalBankItem";
import ForeignBankItem from "./ForeignBankItem";

export default function BankAccountItem({ props }) {
  const { bankAccount, currency, bank, isForeign, foreignBank } = props;

  return (
    <Box mt={4}>
      <Typography variant="h6">{bank?.name || foreignBank}</Typography>
      <Box m={1} />
      <Grid container>
        {isForeign && (
          <Grid item xs={12}>
            <Typography variant="button" color="success.main">
              CUENTA EXTRANJERA
            </Typography>
          </Grid>
        )}
        <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent="space-around"
          >
            <Icon>account_balance</Icon>
            <Typography variant="button" color="primary.main">
              Cuenta
            </Typography>
            <Typography>{bankAccount}</Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          <Stack
            alignItems="center"
            direction="row"
            gap={2}
            justifyContent="space-around"
          >
            <Icon>currency_exchange</Icon>
            <Typography variant="button" color="primary.main">
              Moneda
            </Typography>
            <Typography variant="button" color="success.main">
              {currency?.symbol || "-"}
            </Typography>
            <Typography>{currency?.name}</Typography>
          </Stack>
        </Grid>

        {isForeign ? (
          <ForeignBankItem {...props} />
        ) : (
          <NationalBankItem {...props} />
        )}
      </Grid>
    </Box>
  );
}
