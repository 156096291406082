import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Grid, Button } from '@mui/material';
import MyDatePicker from '../../atoms/Common/MyDatePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const validationSchema = Yup.object().shape({
  shippedQuantity: Yup.number()
    .required('Cantidad es requerida')
    .min(1, 'Debe ser mayor a 0'),
  shippedDate: Yup.date().required('Fecha es requerida'),
});

const CPOShipmentForm = ({ onSubmit, data }) => {
  const initialValues = {
    shippedQuantity: data?.shippedQuantity || 0,
    shippedDate: data?.shippedDate ? dayjs(data.shippedDate) : null,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, errors, touched }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="shippedQuantity"
                label="Cantidad enviada"
                type="number"
                as={TextField}
                fullWidth
                error={touched.shippedQuantity && Boolean(errors.shippedQuantity)}
                helperText={touched.shippedQuantity && errors.shippedQuantity}
              />
            </Grid>
            <Grid item xs={12}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de envío"
                value={values.shippedDate}
                onChange={(value) => setFieldValue('shippedDate', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CPOShipmentForm; 