import { api } from "..";

const endpoint = "/api/client-purchase-orders";

export const getClientPurchaseOrders = async (query) => {
    const response = await api.get(endpoint, { params: query });
    return response;
};

export const getClientPurchaseOrderDetail = async (id) => {
    const response = await api.get(`${endpoint}/${id}`);
    return response;
};  

export const createClientPurchaseOrder = async (data) => {
    const response = await api.post(endpoint, data);
    return response;
};

export const updateClientPurchaseOrder = async (id, data) => {
    const response = await api.put(`${endpoint}/${id}`, data);
    return response;
};

export const deleteClientPurchaseOrder = async (id) => {
    const response = await api.delete(`${endpoint}/${id}`);
    return response;
};

// CPOShipment
export const getCPOShipments = async (query) => {
    const response = await api.get(`${endpoint}/shipments`, { params: query });
    return response;
};  

export const createCPOShipment = async (data) => {
    const response = await api.post(`${endpoint}/shipments`, data);
    return response;
};  

export const updateCPOShipment = async (id, data) => {
    const response = await api.put(`${endpoint}/shipments/${id}`, data);
    return response;
};    

export const deleteCPOShipment = async (id) => {
    const response = await api.delete(`${endpoint}/shipments/${id}`);
    return response;
};      
