import React, { useState } from "react";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import CPOForm from "./CPOForm";
import LGModalCrud from "../LGCrud/LGModalCrud";
import { currencyFormatterMXN, dateFormatter } from "../../services/valueFormatters";
import { deleteClientPurchaseOrder, getClientPurchaseOrders } from "../../api/clientPurchaseOrders";
import LGModal from "../../molecules/LGModal";
import { CheckBox } from "@mui/icons-material";
import dayjs from "dayjs";
import CPOShipments from "./CPOShipments";

const ClientPurchaseOrderCrud = ({ companyId }) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  return (
    <Box width="100%">
      <LGModal
        open={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        header="Exportar CSV"
      >
        <p>Exportar CSV</p>
      </LGModal>
      <LGModalCrud
        props={{
          actions: ["create", "update", "delete", "detail"],
          data: { companyId },
          fetchFunction: getClientPurchaseOrders,
          deleteFunction: deleteClientPurchaseOrder,
          detailMenu: [
            {
              label: "Envíos",
              handler: () => { },
              header: "Envíos",
              body: <CPOShipments />,
            }
          ],
          columns: [
            {
              field: "client",
              headerName: "Cliente",
              valueGetter: (value, row) => row.client?.name || "-",
              flex: 0.1,
            },
            {
              field: "code",
              headerName: "PO",
              flex: 0.1,
            },
            {
              field: "workOrder",
              headerName: "ODT",
              flex: 0.1,
            },
            {
              field: "name",
              headerName: "Nombre",
              flex: 0.2,
            },
            {
              field: "completeDeliveryDate",
              headerName: "Fecha de Entrega Total",
              flex: 0.2,
              ...dateFormatter,
            },
            {
              field: "lastMad",
              headerName: "Last MAD",
              flex: 0.2,
              ...dateFormatter,
            },
            {
              field: "daysUntilMad",
              headerName: "Days until MAD",
              flex: 0.1,
              renderCell: (params) => {
                const lastMad = dayjs(params.row.lastMad);
                const today = dayjs();
                const diffDays = lastMad.diff(today, 'd');
                let chipColor = "success";
                if (diffDays < 0) {
                  chipColor = "error";
                } else if (diffDays <= 5) {
                  chipColor = "warning";
                }

                return (
                  <Chip
                    label={`${diffDays} días`}
                    color={chipColor}
                    size="small"
                  />
                );
              }
            },
            {
              field: "shippedQuantity",
              headerName: "Cantidad Enviada",
              flex: 0.2,
              renderCell: (params) => {
                const shipped = params.row.shippedQuantity ?? 0;
                const total = params.row.quantity;
                const progress = (shipped / total) * 100;
                return (
                  <Box sx={{ width: '100%', p: 1 }}>
                    <Box sx={{
                      width: '100%',
                      height: 24,
                      bgcolor: '#edf4fb',
                      borderRadius: 2,
                      position: 'relative'
                    }}>
                      <Box sx={{
                        width: `${progress}%`,
                        height: '100%',
                        bgcolor: '#a5c8f0',
                        borderRadius: 2,
                        transition: 'width .5s ease-in-out'
                      }} />
                      <Typography
                        variant="caption"
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#1976d2'
                        }}
                      >
                        {`${shipped}/${total}`}
                      </Typography>
                    </Box>
                  </Box>
                );
              }
            },

            {
              field: "rfid",
              headerName: "RFID",
              flex: 0.2,
              renderCell: (params) => (
                <CheckBox
                  checked={params.row.RFID}
                  disabled
                  size="small"
                />
              )
            },
            {
              field: "productionStatus",
              headerName: "Estado de Producción",
              flex: 0.2,
              renderCell: (params) => (
                <Chip
                  size="small"
                  label={params.row.productionStatus?.name.toUpperCase()}
                  color={params.row.productionStatus?.code === "ACTIVE" ? "success" : "default"}
                />
              ),
            },
            {
              field: "shipmentStatus",
              headerName: "Estado de Embarque",
              flex: 0.2,
              renderCell: (params) => (
                <Chip
                  size="small"
                  label={params.row.shipmentStatus?.name.toUpperCase()}
                  color={params.row.shipmentStatus?.code === "ACTIVE" ? "success" : "default"}
                />
              ),
            },
          ],
          addModalData: {
            header: "Agregar CPO",
            body: <CPOForm companyId={companyId} />,
          },
          editModalData: {
            header: "Editar CPO",
            body: <CPOForm companyId={companyId} />,
          },
        }}
      />
    </Box>
  );
};

export default ClientPurchaseOrderCrud;
