import { Box, Grid, Icon, Stack, Typography } from "@mui/material";
import React from "react";

export default function ContactItem({ props }) {
  const { name, contactType, email, phone, mobile, ext } = props;
  return (
    <Box mt={4}>
      <Typography variant="h6">
        {name} | {contactType?.name || ""}
      </Typography>
      <Box m={1} />
      <Grid container>
        <Grid item xs={12}>
          <Stack alignItems="center" direction="row" gap={2}>
            <Icon>email</Icon>
            <Typography>{email}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack alignItems="center" direction="row" gap={2}>
            <Icon>phone</Icon>
            <Typography>{phone}</Typography>
            {ext && (
              <Typography variant="button" color={"primary.main"}>
                ext.
              </Typography>
            )}
            {ext && <Typography variant="button">{ext}</Typography>}

            {/* {ext &&
              ext.map((e, index) => <Typography key={index}>{e}</Typography>)} */}
          </Stack>
        </Grid>
        {mobile && (
          <Grid item xs={12}>
            <Stack alignItems="center" direction="row" gap={2}>
              <Icon>smartphone</Icon>
              <Typography>{mobile}</Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
