import { MenuItem, Select } from '@mui/material';

const YearPicker = ({ 
  value, 
  onChange, 
  startYear = new Date().getFullYear() - 10,
  endYear = new Date().getFullYear() + 10,
}) => {
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      size="small"
    >
      {years.map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      ))}
    </Select>
  );
};

export default YearPicker;