import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import ProviderDetailsForm from "../../molecules/Providers/ProviderDetailsForm";
import ProviderAccountList from "../../molecules/Providers/ProviderAccountList";
import { useFormWithValidation } from "../../hooks/useFormWithValidation.ts";
import {
  addProviderFromAccount,
  getProviderDetail,
  updateProviderFromAccount,
} from "../../api/providers";
import * as Yup from "yup";
import { useSnack } from "../../services/SnakbarProvider.js";
import { handleError } from "../../services/errorHandler.js";

// Move these to a separate types file later
interface ProviderFormProps {
  companyId: number;
  onSubmit: () => void;
  id?: number;
}

const initialValues = {
  provider: {
    legalName: "",
    code: "",
    taxId: "",
    providerOriginId: "",
    providerTypeId: "",
    regimeId: "",
    subregimeId: "",
    corporationTypeId: "",
    descripcion: "",
    website: "",
    creditDays: 0,
  },
  accounts: [{ contpaqiAccountNo: "", description: "", isDefault: true }],
};

const validationSchema = Yup.object().shape({
  provider: Yup.object().shape({
    legalName: Yup.string().required("El nombre legal es obligatorio"),
    code: Yup.string().required("El código es obligatorio"),
    taxId: Yup.string().required("El ID fiscal es obligatorio"),
    providerOriginId: Yup.number()
      .required("El Origen del proveedor es obligatorio")
      .integer(),
    providerTypeId: Yup.number().integer(),
    regimeId: Yup.number().integer().nullable(),
    subregimeId: Yup.number().integer().nullable(),
    corporationTypeId: Yup.number().nullable(),
    descripcion: Yup.string(),
    website: Yup.string().url("URL inválida"),
    creditDays: Yup.number()
      .required("Los días de crédito son obligatorios")
      .integer(),
  }),
  accounts: Yup.array().of(
    Yup.object().shape({
      contpaqiAccountNo: Yup.string().required(
        "La cuenta contable es requerida."
      ),
      description: Yup.string().required("La descripción es requerida."),
    })
  ),
});

const ProviderForm = ({ companyId, onSubmit, id }: ProviderFormProps) => {
  const formMode = id ? "edit" : "insert";
  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const { openSnack } = useSnack();


  const handleSubmit = async (values) => {
    setLoading(true);
    const payload = {
      ...values,
      accounts: values.accounts.map((e) => ({ ...e, companyId })),
    };

    if (formMode === "insert") {
      try{
        await addProviderFromAccount(payload);
        openSnack("Proveedor guardado exitosamente", "success");
        onSubmit();
      }catch(e){
        openSnack(handleError(e), "error");
      }
    } else {
      try{
        await updateProviderFromAccount(id, payload);
        openSnack("Proveedor actualizado exitosamente", "success");
        onSubmit();
      }catch(e){
        openSnack(handleError(e), "error");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (formMode === "edit") {
        const res = await getProviderDetail(id, companyId);
        setFormData({
          provider: res.data,
          accounts: res.data.accounts,
        });
      } else {
        setFormData(initialValues);
      }
    };

    fetchData();
  }, [formMode, id, companyId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Formik
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form id={id?.toString()}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography pb={2} pt={4} variant="h4">
                Datos Generales
              </Typography>
              <ProviderDetailsForm errors={errors} touched={touched} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" pb={2} pt={4}>
                Cuentas Contables
              </Typography>
              <ProviderAccountList
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                formMode={formMode}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" onClick={() => {
                console.log(errors);
                console.log(values);
              }}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderForm;
