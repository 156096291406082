import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import {
  addExport,
  createExport,
  getExportById,
  getExportDetail,
  updateExport,
} from "../../api/exports";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getCatalogue, getStatusCatalogue } from "../../api/common/catalogues";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import CompanyDropdownSelector from "../../atoms/Common/CompanyDropdownSelector";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import MyDatePicker from "../../atoms/Common/MyDatePicker";
import { getClientList } from "../../api/client";
import PropTypes from "prop-types";
import TariffFractionForm from "./TariffFractionForm";
import { getFinishedGoodList } from "../../api/finishedGoods";
import ExportItemsForm from "./ExportItemsForm";

const validationSchema = Yup.object().shape({
  export: Yup.object().shape({
    statusId: Yup.number().required("Seleccione un estado"),
    forwarderId: Yup.number().required("Seleccione un forwarder"),
    packingListDate: Yup.date().required(
      "Fecha de packing list es obligatoria"
    ),
    agentDeliveryDate: Yup.date().required("Fecha de entrega es obligatoria"),
    requestNo: Yup.string(),
    lockNo: Yup.string(),
    containerTypeId: Yup.number().required("Seleccione un tipo de contenedor"),
    shipmentTypeId: Yup.number().required("Seleccione un tipo de embarque"),
  }),
  exportItems: Yup.array().of(
    Yup.object().shape({
      clientPurchaseOrderId: Yup.number().required(
        "Seleccione una orden de compra"
      ),
      itemQuantity: Yup.number().required("Cantidad es obligatoria").positive(),
      palletQuantity: Yup.number()
        .required("Cantidad de pallets es obligatoria")
        .positive(),
      volume: Yup.number().required("Volumen es obligatorio").positive(),
      tariffFractionId: Yup.number().required(
        "Seleccione una fracción arancelaria"
      ),
      invoiceDate: Yup.date().required("Fecha de factura es obligatoria"),
      invoiceAmount: Yup.number()
        .required("Monto de factura es obligatorio")
        .positive(),
      netWeight: Yup.number().required("Peso neto es obligatorio").positive(),
    })
  ),
});

const initialValues = {
  export: {
    companyId: null,
    statusId: null,
    forwarderId: null,
    packingListDate: null,
    agentDeliveryDate: null,
    volume: 0,
    requestNo: "",
    lockNo: "",
    containerTypeId: null,
    shipmentTypeId: null,
  },
  exportItems: [
    {
      clientPurchaseOrderId: "",
      itemQuantity: 0,
      palletQuantity: 0,
      tariffFractionId: "",
      invoiceDate: null,
      invoiceAmount: 0,
      netWeight: 0,
    },
  ],
};

const ExportForm = ({ onSubmit, id, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [clientOptions, setClientOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getExportById(id);
      setFormData(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const fetchClientOptions = async (inputValue) => {
    try {
      const res = await getClientList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setClientOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const handleSubmit = async (values) => {
    const formatDate = (date) => (date ? dayjs(date).format("YYYY-MM-DD") : "");

    const formattedValues = {
      export: {
        ...values.export,
        companyId,
        packingListDate: formatDate(values.export.packingListDate),
        agentDeliveryDate: formatDate(values.export.agentDeliveryDate),
      },
      exportItems: values.exportItems.map((item) => ({
        ...item,
        invoiceDate: formatDate(item.invoiceDate),
      })),
    };

    try {
      if (formMode === "insert") {
        await createExport(formattedValues);
        openSnack("Exportación guardada exitosamente", "success");
      } else {
        await updateExport(id, formattedValues);
        openSnack("Exportación actualizada exitosamente", "success");
      }
      onSubmit();
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchClientOptions();
    if (formMode === "edit") {
      fetchData();
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">Datos de Exportación</Typography>
            </Grid>

            <Grid item xs={6}>
              <Field
                name="export.statusId"
                label="Estatus"
                as={DropdownSelector}
                fetchFunction={getStatusCatalogue}
                fetchParams={"EXPORT"}
              />
            </Grid>

            {/* <Grid item xs={6}>
              <Autocomplete
                id="export.clientId"
                options={clientOptions}
                getOptionLabel={(option) => option.code || ""}
                value={
                  clientOptions.find(
                    (option) => option.id === values.export.clientId
                  ) || null
                }
                onInputChange={(event, value) => fetchClientOptions(value)}
                onChange={(event, value) => {
                  setFieldValue("export.clientId", value ? value.id : null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cliente"
                    error={touched?.export?.clientId && Boolean(errors?.export?.clientId)}
                    helperText={touched?.export?.clientId && errors?.export?.clientId}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={6}>
              <Field
                name="export.forwarderId"
                label="Forwarder"
                as={DropdownSelector}
                fetchFunction={getCatalogue}
                fetchParams={"EXPORT_FORWARDER"}
              />
            </Grid>

            <Grid item xs={6}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de Packing List"
                value={
                  values.export.packingListDate
                    ? dayjs(values.export.packingListDate)
                    : null
                }
                onChange={(value) =>
                  setFieldValue("export.packingListDate", value)
                }
              />
            </Grid>

            <Grid item xs={6}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de Entrega"
                value={
                  values.export.agentDeliveryDate
                    ? dayjs(values.export.agentDeliveryDate)
                    : null
                }
                onChange={(value) =>
                  setFieldValue("export.agentDeliveryDate", value)
                }
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                name="export.containerTypeId"
                label="Tipo de Contenedor"
                as={DropdownSelector}
                fetchFunction={getCatalogue}
                fetchParams={"EXPORT_CONTAINER_TYPE"}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                name="export.shipmentTypeId"
                label="Tipo de Embarque"
                as={DropdownSelector}
                fetchFunction={getCatalogue}
                fetchParams={"EXPORT_SHIPMENT_TYPE"}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <TariffFractionForm
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Items de Exportación</Typography>
              <Box sx={{ mt: 2 }}>
                <ExportItemsForm
                  values={values}
                  setFieldValue={setFieldValue}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                onClick={() => console.log(errors, values)}
              >
                {formMode === "edit" ? "Actualizar" : "Crear"} Exportación
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ExportForm;
