import React from "react";
import { Grid, TextField } from "@mui/material";
import { Field } from "formik";
import CurrencyFieldText from "../../atoms/Inputs/CurrencyField";
import PropTypes from "prop-types";

const TariffFractionForm = ({ values, handleChange, handleBlur, touched, errors }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="requestNo"
          label="Número de Pedimento"
          value={values.requestNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.requestNo && Boolean(errors.requestNo)}
          helperText={touched.requestNo && errors.requestNo}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          fullWidth
          name="lockNo"
          label="Número de Candado"
          value={values.lockNo}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lockNo && Boolean(errors.lockNo)}
          helperText={touched.lockNo && errors.lockNo}
        />
      </Grid>
    </Grid>
  );
};

TariffFractionForm.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default TariffFractionForm;
