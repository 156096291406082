import * as React from "react";
import { useState } from "react";
import LoginForm from "../../organisms/Auth/LoginForm";
import {
  Button,
  Avatar,
  Box,
  Typography,
  Container,
  CssBaseline,
} from "@mui/material";
import RegisterForm from "../../organisms/Auth/RegisterForm";
import DashboardLayout from "../../layouts/DashboardLayout";

export default function RegisterScreen() {
  return (
    <DashboardLayout>
      <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, width: 64, height: 64 }}
              variant="square"
              src={`${process.env.PUBLIC_URL}/assets/Logo-LG-2.png`}
            ></Avatar>

            <RegisterForm />
          </Box>
      </Container>
    </DashboardLayout>
  );
}
