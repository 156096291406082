import axios from "axios";

export const handleError = (error) => {
  if (axios.isAxiosError(error)) {
    console.log(error);
    if (error.code == "ERR_NETWORK") {
      return "Error de conexión";
    }
    var err = error.response;
    if (Array.isArray(err?.data)) {
      for (let val of err?.data) {
        return val.description;
      }
    } else if (typeof err?.data === "object") {
      for (let e in err?.data) {
        return err.data[e][0];
      }
    } else if (err?.data) {
      return err.data;
    } else if (err?.status == 401) {
      return "Acción no válida para este usuario";
    } else if (err) {
      return err?.data;
    }
  }
};
