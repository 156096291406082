import React from "react";
import { Grid, TextField } from "@mui/material";
import { Field, FastField } from "formik";
import DropdownSelector from "../../../atoms/Common/DropdownSelector";
import { getCatalogue } from "../../../api/common/catalogues";
import { getCurrencyList } from "../../../api/common/currencies";

const NationalBankForm = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <FastField
        name="bankId"
        as={DropdownSelector}
        fetchFunction={getCatalogue}
        fetchParams={{ CatalogueName: "BANKS" }}
        label="Banco"
        fullWidth
      />
    </Grid>

    <Grid item xs={8}>
      <Field
        name="bankAccount"
        as={TextField}
        label="Cuenta Bancaria"
        fullWidth
      />
    </Grid>
    <Grid item xs={4}>
      <Field
        name="currencyId"
        as={DropdownSelector}
        fetchFunction={getCurrencyList}
        label="Moneda"
        optionLabel="symbol"
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <Field name="clabe" as={TextField} label="CLABE" fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Field name="cie" as={TextField} label="CIE" fullWidth />
    </Grid>
    <Grid item xs={12}>
      <Field name="cieRef" as={TextField} label="Referencia CIE" fullWidth />
    </Grid>
  </Grid>
);

export default NationalBankForm;
