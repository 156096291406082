import React, { useEffect, useState } from "react";
import LGCrud from "../../LGCrud";
import {
  addProviderActivity,
  deleteProviderActivity,
  getProviderActivityList,
  updateProviderActivity,
} from "../../../api/providers";
import LGSelectCellRenderer from "../../LGCrud/LGSelectCellRenderer";
import LGSelectCellEditor from "../../LGCrud/LGSelectCellEditor";
import { getExpenseAccountList } from "../../../api/accounting";

export default function ActivityTable({ props }) {
  const { id } = props;
  const [expenseAccounts, setExpenseAccounts] = useState([]);

  const fetchExpenseAccounts = async () => {
    try {
      const res = await getExpenseAccountList();
      setExpenseAccounts(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const handlePost = async (data) => {
    const payload = { ...data };
    payload.expenseAccountId = payload.expenseAccount.id;
    payload.providerId = id;
    try {
      const res = await addProviderActivity(payload);
    } catch (e) {}
  };

  const handleUpdate = async (id, data) => {
    const payload = { ...data };
    const res = await updateProviderActivity(id, payload);
  };

  const handleDelete = async (id) => {
    const res = await deleteProviderActivity(id);
  };

  useEffect(() => {
    if (id) {
      fetchExpenseAccounts();
    }
  }, [id]);

  return (
    <LGCrud
      parentId={{ name: "providerId", value: id }}
      columns={[
        {
          field: "name",
          headerName: "Nombre",
          flex: 0.5,
          editable: true,
        },
        {
          field: "expenseAccount",
          headerName: "Actividad económica",
          flex: 0.5,
          editable: true,
          renderCell: (params) => (
            <LGSelectCellRenderer
              value={params.value}
              labelTemplate={(v) => `${v.name}`}
            />
          ),
          renderEditCell: (params) => (
            <LGSelectCellEditor
              {...params}
              dropdownData={expenseAccounts}
              labelTemplate={(v) =>
                `${v.name} | ${v.economicActivity.productServiceType.code}-${v.economicActivity.contpaqiPrefixCode}-${v.contpaqiSuffixCode}`
              }
            />
          ),
        },
      ]}
      actionHandlers={{
        create: handlePost,
        update: handleUpdate,
        delete: handleDelete,
        fetchData: getProviderActivityList,
      }}
    />
  );
}
