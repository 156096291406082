import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeOut = keyframes`
  0% { opacity: 1; transform: scale(1); }
  50% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 0; transform: scale(0.8); }
`;

const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.8); }
  50% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
`;

const LogoTransition = ({ oldCompany, newCompany }) => {
  const getLogoPath = (companyId) => {
    const companyPrefix = companyId === 1 ? "lg" : companyId === 2 ? "tl" : "el";
    const envPrefix = process.env.REACT_APP_ENV === "LOCAL" ? "dev" : 
                     process.env.REACT_APP_ENV === "QA" ? "qa" : "prod";
    return `/assets/Imgs/logos/${companyPrefix}-${envPrefix}.png`;
  };

  return (
    <Box sx={{ position: 'relative', width: '200px', height: '80px' }}>
      <Box
        component="img"
        src={getLogoPath(oldCompany)}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          animation: `${fadeOut} 1s ease-in-out forwards`,
        }}
      />
      <Box
        component="img"
        src={getLogoPath(newCompany)}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          animation: `${fadeIn} 1s ease-in-out forwards`,
        }}
      />
    </Box>
  );
};

export default LogoTransition; 