import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ShipmentReportCharts from "../../molecules/Reports/ShipmentReportCharts";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getShipmentList } from "../../api/imports";

const ShipmentReports = ({ companyId }) => {
  const [loading, setLoading] = useState(true);
  const [containersData, setContainersData] = useState([]);
  const [delayFeesData, setDelayFeesData] = useState([]);
  const { openSnack } = useSnack();
  const [year, setYear] = useState(new Date().getFullYear());

  const fetchReportData = async () => {
    try {
      setLoading(true);
      const response = await getShipmentList({ companyId, year });
      const shipments = response.data;

      // Create array of all months in Spanish
      let allMonths = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];

      // Initialize objects with all months set to 0
      let monthlyContainers = allMonths.reduce((acc, month) => {
        acc[month] = 0;
        return acc;
      }, {});

      let monthlyDelayFees = allMonths.reduce((acc, month) => {
        acc[month] = 0;
        return acc;
      }, {});

      // Update values from actual shipments
      shipments.forEach((shipment) => {
        if (!shipment.plantArrivalDate) return;

        const date = new Date(shipment.plantArrivalDate);
        const month = date.toLocaleString("es-MX", { month: "short" });

        // Capitalize first letter to match allMonths format
        const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1);

        if (monthlyContainers.hasOwnProperty(formattedMonth)) {
          monthlyContainers[formattedMonth] += Array.isArray(
            shipment.containers
          )
            ? shipment.containers.length
            : 0;
          monthlyDelayFees[formattedMonth] +=
            Number(shipment.delayCharges) || 0;
        }
      });

      // Convert to array format needed by charts, maintaining month order
      const containersData = allMonths.map((month) => ({
        month,
        value: monthlyContainers[month],
      }));

      const delayFeesData = allMonths.map((month) => ({
        month,
        value: monthlyDelayFees[month],
      }));

      setContainersData(containersData);
      setDelayFeesData(delayFeesData);
    } catch (e) {
      openSnack(handleError(e), "error");
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportData();
  }, [companyId, year]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={3}>
        <FormControl>
          <InputLabel>Año</InputLabel>
          <Select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            label="Año"
          >
            {Array.from({ length: 2040 - 2020 + 1 }, (_, i) => 2020 + i).map(
              (year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Box>
      <ShipmentReportCharts
        containersData={containersData}
        delayFeesData={delayFeesData}
      />
    </Box>
  );
};

export default ShipmentReports;
