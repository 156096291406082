import { api } from "..";

const endpoint = "/api/currencies";

// GET
export const getCurrencyList = () => {
  return api.get(`${endpoint}`);
};

// POST
export const addCurrency = (data) => {
  return api.post(`${endpoint}`, data);
};

// PUT
export const updateCurrency = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

// DELETE
export const deleteCurrency = (id) => {
  return api.delete(`${endpoint}/${id}`);
};
