import React from "react";
import { Grid, TextField } from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getExpenseAccountList } from "../../api/accounting";
import { getCatalogue } from "../../api/common/catalogues";
import { getProductCategoryList } from "../../api/products";

export default function ProductFormHeader({
  companyId,
  setProductType,
}): JSX.Element {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext();

  const handleProductTypeChange = (event: any, value: any) => {
    console.log("Product type changed:", value);
    setFieldValue("productTypeId", value?.id);
    setProductType(value?.code);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          name="productCategoryId"
          type="text"
          label="Categoría de Producto"
          as={DropdownSelector}
          fetchFunction={getProductCategoryList}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          as={DropdownSelector}
          name="productTypeId"
          fetchFunction={getCatalogue}
          fetchParams={{ catalogueName: "PRODUCT_TYPE" }}
          label="Tipo de Producto"
          onChange={handleProductTypeChange}
          value={values.productTypeId}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          fullWidth
          name="originalCode"
          type="text"
          label="Código Original"
          as={TextField}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FastField
          fullWidth
          name="expenseAccountId"
          as={DropdownSelector}
          fetchFunction={getExpenseAccountList}
          fetchParams={{ companyId }}
          label="Cuenta de Gastos"
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          fullWidth
          name="description"
          type="text"
          label="Descripción"
          as={TextField}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}
