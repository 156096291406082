import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import CrudClient from "../../organisms/Client/CrudClient.tsx";
import { useCompany } from "../../contexts/CompanyContext";
import { Box, Typography } from "@mui/material";

export default function ClientScreen(): JSX.Element {
  const { selectedCompany } = useCompany();
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Force re-render of CrudClient when selectedCompany changes
    setKey(prevKey => prevKey + 1);
  }, [selectedCompany]);

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <CrudClient key={key} companyId={selectedCompany.id} />
    </DashboardLayout>
  );
}
