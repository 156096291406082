import React, { useEffect, useState } from "react";
import { useSnack } from "../../services/SnakbarProvider";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import { getShipmentList } from "../../api/imports";
import { handleError } from "../../services/errorHandler";

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const highlightedDates = highlightedDays;

  // Check if the current day is highlighted and it's within the current month
  const isSelected =
    !outsideCurrentMonth &&
    highlightedDates.some(
      (highlightedDay) => highlightedDay.isSame(day, "day") // Compare both day and month
    );

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isSelected ? "✅" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}
export default function ShipmentCalendar({ companyId }) {
  const [shipmentDates, setShipmentDates] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      const res = await getShipmentList({ companyId: companyId });
      const dates = res.data.map((e) => dayjs(e.portArrivalEta));
      setShipmentDates(dates);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        sx={{
          width: "100%",
          height: "100%",
          maxHeight: "600px",
          // maxWidth: "500px", // or any desired width
          "& .MuiPickersDay-root": {
            width: "80px", // Increase size of individual day cells
            height: "80px",
          },
          "& .MuiDayCalendar-weekDayLabel": {
            width: "80px",
          },
          "& .MuiDayCalendar-root ": {
            height: "600px",
            maxHeight: "600px",
          },
          "& .MuiDayCalendar-viewTransitionContainer": {
            height: "600px",
            maxHeight: "600px",
          },
          "& .MuiDayPicker-monthContainer": {
            flex: "1 1 auto", // Ensure month container fills available space
          },
          "& .MuiDayCalendar-slideTransition": {
            height: "600px",
          },
          "& .MuiPickerSlideTransition-root": {
            height: "600px",
          },
          "& .MuiPickersSlideTransition-root": {
            height: "600px",
          },
        }}
        renderLoading={() => <DayCalendarSkeleton />}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays: shipmentDates,
          },
        }}
      />
    </LocalizationProvider>
  );
}
