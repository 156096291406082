import dayjs from "dayjs";

export const percentageFormatter = {
  type: "number",
  valueFormatter: (value) =>
    value != undefined
      ? `${value.toLocaleString(undefined, { minimumFractionDigits: 4 })} %`
      : "",
};

export const dateFormatter = {
  valueFormatter: (value) => dayjs(value).format("DD/MM/YY"),
};

export const currencyFormatterMXN = {
  // type: "number",
  valueFormatter: (value) =>
    value != undefined ? formatCurrency(value) : value,
};

export const formatCurrency = (value, currency = "MXN") => {
  if (!value) return "";
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: currency,
  }).format(value);
};
