import { GridActionsCellItem } from "@mui/x-data-grid";
import React from "react";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

export default function LGCrudEditMode(props) {
  const { id, onSave, onCancel } = props;
  return [
    <GridActionsCellItem
      key="save"
      icon={<SaveIcon />}
      label="Save"
      sx={{
        color: "primary.main",
      }}
      onClick={onSave(id)}
    />,
    <GridActionsCellItem
      key="cancel"
      icon={<CancelIcon />}
      label="Cancel"
      className="textPrimary"
      onClick={onCancel(id)}
      color="inherit"
    />,
  ];
}
