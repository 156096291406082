import React from "react";
import { Field, FieldArray } from "formik";
import {
  TextField,
  Grid,
  Typography,
  Box,
  IconButton,
  Stack,
  Icon,
  Button,
} from "@mui/material";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

const ShipmentContainers = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h4">Contenedores</Typography>
      </Grid>
      <Grid item xs={12}>
        <FieldArray name="containers">
          {({ push, remove }) => (
            <Box>
              <Grid container spacing={2}>
                {values.containers &&
                  values.containers.map((_, index) => (
                    <Grid container spacing={2} key={index} pl={2}>
                      <Grid item xs={6} key={index}>
                        <Field
                          as={TextField}
                          fullWidth
                          name={`containers.${index}.code`}
                          label={`Contenedor ${index + 1}`}
                          value={values.containers[index].code}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.containers?.[index]?.code &&
                            Boolean(errors.containers?.[index]?.code)
                          }
                          helperText={
                            touched.containers?.[index]?.code &&
                            errors.containers?.[index]?.code
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                      <Field
                          as={MyDatePicker}
                          name={`containers.${index}.deliveryDate`}
                          label={`Fecha de entrega`}
                          value={values.containers[index].deliveryDate}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.containers?.[index]?.deliveryDate &&
                            Boolean(errors.containers?.[index]?.deliveryDate)
                          }
                          helperText={
                            touched.containers?.[index]?.deliveryDate &&
                            errors.containers?.[index]?.deliveryDate
                          }
                        />
                      </Grid>
                      <Grid item xs={1}>
                      <IconButton onClick={() => remove(index)} color="error">
                          <Icon>delete</Icon>
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => push({ code: "" })}
                    color="primary"
                  >
                    <Icon>add</Icon>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </FieldArray>
      </Grid>
    </Grid>
  );
};

export default ShipmentContainers;
