import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Grid, 
  Typography, 
  CircularProgress,
  Container
} from '@mui/material';
import DashboardLayout from '../../layouts/DashboardLayout';
import PurchaseOrderCard from '../../molecules/PurchaseOrder/PurchaseOrderCard';
import { approvePurchaseOrder, getPurchaseOrderList } from '../../api/purchaseOrders';
import { useSnack } from '../../services/SnakbarProvider';
import { handleError } from '../../services/errorHandler';
import { useCompany } from '../../contexts/CompanyContext';

interface PurchaseOrder {
  id: number;
  code: string;
  provider: {
    code: string;
  };
  total: number;
  createdAt: string;
  currency: {
    code: string;
  };
  createdBy: string;
  items: Array<{
    workOrderId?: string;
  }>;
}

const ApprovalScreen = () => {
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const { openSnack } = useSnack();
  const { selectedCompany } = useCompany();

  const fetchPurchaseOrders = async () => {
    try {
      setLoading(true);
      const response = await getPurchaseOrderList({
        companyId: selectedCompany?.id,
        // Add filter for pending approval status
        statusCode: 'PO_SIN_APROBAR'
      });
      setPurchaseOrders(response.data);
    } catch (error) {
      openSnack(handleError(error), 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id: number) => {
    try {
      await approvePurchaseOrder(id);
      fetchPurchaseOrders();
      openSnack('Órden de compra aprobada', 'success');
    } catch (error) {
      openSnack(handleError(error), 'error');
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchPurchaseOrders();
    }
  }, [selectedCompany]);

  if (!selectedCompany) {
    return (
      <DashboardLayout>
        <Box p={4}>
          <Typography>Seleccione una compañía primero</Typography>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Container maxWidth="xl">
        <Box py={3}>
          <Typography variant="h4" gutterBottom>
            Aprobaciones Pendientes
          </Typography>
          
          {loading ? (
            <Box display="flex" justifyContent="center" py={4}>
              <CircularProgress />
            </Box>
          ) : purchaseOrders.length === 0 ? (
            <Box py={4}>
              <Typography variant="h6" color="text.secondary" align="center">
                No hay órdenes de compra pendientes de aprobación
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={3}>
              {purchaseOrders.map((po) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={po.id}>
                  <PurchaseOrderCard 
                    purchaseOrder={po}
                    onApprove={() => handleApprove(po.id)}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalScreen;
