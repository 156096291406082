import React, { useState } from "react";
import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import { deletePayment, exportPaymentCSV, exportPaymentLayout, getPaymentList, payPayment } from "../../api/payments";
import { useSnack } from "../../services/SnakbarProvider";
import { currencyFormatterMXN } from "../../services/valueFormatters";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

export default function CrudPayment({ props }) {
  const { companyId, statusCode } = props;
  const { openSnack } = useSnack();

  const [startDate, setStartDate] = useState(dayjs().subtract(1, "week"));
  const [endDate, setEndDate] = useState(dayjs());
  const [dateType, setDateType] = useState("PaymentDate");


  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "PAYMENT_PENDING":
        return "warning";
      case "PAYMENT_PAID":
        return "success";
      case "CANCELADO":
        return "error";
      default:
        return "default";
    }
  };

  const handleStatusChange = async (selectedRows) => {
    try {
      await payPayment(selectedRows);
      
      const response = await exportPaymentCSV(selectedRows);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `pagos_${dayjs().format('YYYYMMDD_HHmmss')}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      openSnack("Pagos programados y descargados correctamente", "success");
    } catch (error) {
      openSnack("Error al pagar", "error");
    }
  };

  const handleExportLayout = async (selectedRows) => {
    if (selectedRows.length === 0) {
      openSnack("Selecciona al menos un pago", "warning");
      return;
    }
    try {
      const layout = await exportPaymentLayout(selectedRows);
      const fileName = `poliza_egreso_${dayjs().format('YYYYMMDD_HHmmss')}.txt`;

      const url = window.URL.createObjectURL(new Blob([layout.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      openSnack("Error al exportar layout", "error");
    }
  };

  return (
    <Box width={"100%"}>
      <Stack direction="row" spacing={2} mb={2}>
        <MyDatePicker
          label="Fecha Inicial"
          value={startDate}
          onChange={(newValue) => setStartDate(dayjs(newValue))}
        />
        <MyDatePicker
          label="Fecha Final"
          value={endDate}
          onChange={(newValue) => setEndDate(dayjs(newValue))}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Tipo de Fecha</InputLabel>
          <Select
            label="Tipo de Fecha"
            value={dateType}
            onChange={(e) => setDateType(e.target.value)}
          >
            <MenuItem value="PaymentDate">Fecha de pago</MenuItem>
            <MenuItem value="CreatedDate">Fecha de creación</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <LGModalCrud
        props={{
          actions: ["delete", "detail", "select"],
          data: {
            companyId,
            startDate: startDate?.format("YYYY-MM-DD"),
            endDate: endDate?.format("YYYY-MM-DD"),
            statusCode,
            dateType
          },
          fetchFunction: getPaymentList,
          deleteFunction: deletePayment,
          customButtons: [
            ...(statusCode === "PAYMENT_SCHEDULED" ? [{
              label: "Pagar y descargar formato para banco",
              icon: "price_check",
              color: "secondary",
              onClick: handleStatusChange,
              showOnlyWithSelection: true,
            }] : []),
            ...(statusCode === "PAYMENT_PAID" ? [{
              label: "Exportar Layout",
              icon: "file_download",
              color: "secondary",
              onClick: handleExportLayout,
              showOnlyWithSelection: true,
            }] : []),
          ],
          columns: [
            {
              field: "code",
              headerName: "Folio",
              flex: 0.2,
            },
            {
              field: "provider",
              headerName: "Proveedor",
              flex: 0.3,
              valueGetter: (value, row) =>
                row.provider ? `${row.provider.code}` : "",
            },
            {
              field: "amount",
              headerName: "Monto",
              valueGetter: (value, row) => row.total,
              flex: 0.2,
              ...currencyFormatterMXN,
            },
            {
              field: "paymentDate",
              headerName: "Fecha de Pago",
              flex: 0.2,
            },
            {
              field: "status",
              headerName: "Estatus",
              flex: 0.2,
              renderCell: (params) => (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    size="small"
                    label={params.row.status.name.toUpperCase()}
                    color={setStatusColor(params.row.status.code)}
                  />
                </Box>
              ),
            },
          ],
          //   addModalData: {
          //     header: "Agregar Pago",
          //     body: <PaymentForm companyId={companyId} />,
          //   },
          //   editModalData: {
          //     header: "Editar Pago",
          //     body: <PaymentForm companyId={companyId} />,
          //   },
        }}
      />
    </Box>
  );
}
