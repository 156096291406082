import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { getRoles, register } from "../../../api/auth";
import { getCompanyList } from "../../../api/enterprise";
import { handleError } from "../../../services/errorHandler";
import { useSnack } from "../../../services/SnakbarProvider";

function RegisterForm() {
  const { openSnack } = useSnack();
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);

  const validationSchema = Yup.object({
    username: Yup.string()
      .trim()
      .required("El usuario es requerido")
      .matches(/^\S*$/, "El usuario no puede tener espacios"),
    email: Yup.string("Ingresa un email válido")
      .email("Ingresa un email válido")
      .required("El email es requerido"),
    password: Yup.string("Ingresa una contraseña")
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .required("La contraseña es requerida")
      .test(
        "isValidPass",
        "Necesita al menos una mayuscula, una minúscula y un número",
        (value) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          return hasUpperCase && hasNumber && hasLowerCase;
        }
      ),
    roleCompanyMappings: Yup.array().of(
      Yup.object().shape({
        roleId: Yup.string().required("El rol es requerido"),
        companyId: Yup.number().required("La empresa es requerida"),
      })
    ).min(1, "Debe agregar al menos un rol y empresa"),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      roleCompanyMappings: [{ roleId: '', companyId: '' }]
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await register(values);
        openSnack("Usuario registrado correctamente", "success");
      } catch (e) {
        openSnack(handleError(e), "error");
      }
    },
  });

  const addRoleCompanyMapping = () => {
    const mappings = [...formik.values.roleCompanyMappings];
    mappings.push({ roleId: '', companyId: '' });
    formik.setFieldValue('roleCompanyMappings', mappings);
  };

  const removeRoleCompanyMapping = (index) => {
    const mappings = [...formik.values.roleCompanyMappings];
    mappings.splice(index, 1);
    formik.setFieldValue('roleCompanyMappings', mappings);
  };

  const fetchData = async () => {
    try {
      const [rolesResponse, companiesResponse] = await Promise.all([
        getRoles(),
        getCompanyList()
      ]);
      setRoles(rolesResponse.data);
      setCompanies(companiesResponse.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Typography component="h1" variant="h5">
        Registrar nuevo usuario
      </Typography>
      <Box component="form" sx={{ mt: 1 }} onSubmit={formik.handleSubmit}>
        <TextField
          required
          fullWidth
          margin="normal"
          id="username"
          label="Usuario"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          autoFocus
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Contraseña"
          type="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />

        {formik.values.roleCompanyMappings.map((mapping, index) => (
          <Grid container spacing={2} key={index} sx={{ mt: 2 }}>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Rol</InputLabel>
                <Select
                  name={`roleCompanyMappings.${index}.roleId`}
                  value={mapping.roleId}
                  label="Rol"
                  onChange={formik.handleChange}
                >
                  {roles.map((role) => (
                    <MenuItem value={role.id} key={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <InputLabel>Empresa</InputLabel>
                <Select
                  name={`roleCompanyMappings.${index}.companyId`}
                  value={mapping.companyId}
                  label="Empresa"
                  onChange={formik.handleChange}
                >
                  {companies.map((company) => (
                    <MenuItem value={company.id} key={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
              {index > 0 && (
                <IconButton onClick={() => removeRoleCompanyMapping(index)} color="error">
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Button
          startIcon={<AddIcon />}
          onClick={addRoleCompanyMapping}
          sx={{ mt: 2 }}
        >
          Agregar rol y empresa
        </Button>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Registrar usuario
        </Button>
      </Box>
    </>
  );
}

export default RegisterForm;
