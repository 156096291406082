import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Typography } from "@mui/material";

export default function HomeScreen() {
  return (
    <DashboardLayout>
      <Typography variant="body2">Bienvenido</Typography>
    </DashboardLayout>
  );
}
